const userAccess = {
    ADMIN: {
        NAME: 'Administrador',
        ACCESS_LEVEL: 1
    },
    COMMERCE: {
        NAME: 'Comercio',
        ACCESS_LEVEL: 2
    },
    ESTABLISHMENT: {
        NAME: 'Establecimiento',
        ACCESS_LEVEL: 3
    },
    SELLER: {
        NAME: 'Vendedor',
        ACCESS_LEVEL: 4
    }
}

Object.freeze(userAccess)

export default userAccess

import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Redirect, Switch } from 'react-router'
import BlockUi from 'react-block-ui'
import SellersEditForm from '../pages/Sellers/FormEdit'
import PayInvoice from '../pages/PayInvoice'
import PayWithoutAmount from '../pages/PayWithoutAmount'
import StatusInvoice from '../pages/StatusInvoice'
import ExpiredInvoice from '../pages/ExpiredInvoice'

const Home = React.lazy(() => import('../pages/home'))
const Login = React.lazy(() => import('../pages/Login'))
const Otp = React.lazy(() => import('../pages/Otp'))
const OldPayment = React.lazy(() => import('../pages/OldPayment'))
const ResetPassword = React.lazy(() => import('../pages/PasswordReset'))
const ForgotPassword = React.lazy(() => import('../pages/PasswordForgot'))
const ChangePassword = React.lazy(() => import('../pages/PasswordChange'))
const NotFound = React.lazy(() => import('../pages/404'))
const Faqs = React.lazy(() => import('../pages/Faqs'))
const PrivacyPolicy = React.lazy(() => import('../pages/PrivacyPolicy'))

const SellersList = React.lazy(() => import('../pages/Sellers/List'))
const SellersForm = React.lazy(() => import('../pages/Sellers/Form'))

const InvoicesList = React.lazy(() => import('../pages/Invoices/List'))
const InvoicesForm = React.lazy(() => import('../pages/Invoices/Form'))
const InvoicesShow = React.lazy(() => import('../pages/Invoices/Show'))

const CommerceList = React.lazy(() => import('../pages/Commerce/List'))
const CommerceForm = React.lazy(() => import('../pages/Commerce/Form'))
const CommerceBulkLoad = React.lazy(() => import('../pages/Commerce/Bulk'))

const EstablishmentList = React.lazy(() => import('../pages/Establishment/List'))
const EstablishmentForm = React.lazy(() => import('../pages/Establishment/Form'))

const AdministratorList = React.lazy(() => import('../pages/Administrator/List'))
const AdministratorForm = React.lazy(() => import('../pages/Administrator/Form'))
const AdministratorFormEdit = React.lazy(() => import('../pages/Administrator/FormEdit'))

const ClientHistory = React.lazy(() => import('../pages/ClientHistory'))

const Routes = () => (
    <React.Suspense fallback={<BlockUi blocking />}>
        <Router basename={process.env.REACT_APP_SUB_DIRECTORY}>
            <Switch>
                <Route exact path="/" render={(props) => <Home {...props} />} />
                <Route path="/login" render={(props) => <Login {...props} />} />
                <Route path="/otp" render={(props) => <Otp {...props} />} />
                <Route path="/P2P" render={(props) => <OldPayment {...props} />} />
                <Route path="/faqs" render={(props) => <Faqs {...props} />} />
                <Route path="/privacy-policy" render={(props) => <PrivacyPolicy {...props} />} />
                <Route path="/password/reset" render={(props) => <ResetPassword {...props} />} />
                <Route path="/password/forgot" render={(props) => <ForgotPassword {...props} />} />
                <Route path="/password/change" render={(props) => <ChangePassword {...props} />} />
                <Route path="/user/profile" render={(props) => <ResetPassword {...props} />} />
                <Route path="/user/forgot" render={(props) => <ForgotPassword {...props} />} />
                <Route path="/historic" render={(props) => <ClientHistory {...props} />} />

                <Route exact path="/sellers">
                    <Redirect to="/sellers/list" />
                </Route>
                <Route path="/sellers/list" render={(props) => <SellersList {...props} />} />
                <Route exact path="/sellers/form" render={(props) => <SellersForm {...props} />} />
                <Route
                    path="/sellers/form/:id"
                    render={(props) => <SellersEditForm {...props} />}
                />

                <Route exact path="/commerces">
                    <Redirect to="/commerce/list" />
                </Route>
                <Route path="/commerce/list" render={(props) => <CommerceList {...props} />} />
                <Route path="/commerce/form/:id" render={(props) => <CommerceForm {...props} />} />
                <Route path="/commerce/form" render={(props) => <CommerceForm {...props} />} />
                <Route path="/commerce/bulk" render={(props) => <CommerceBulkLoad {...props} />} />

                <Route exact path="/establishments">
                    <Redirect to="/establishments/list" />
                </Route>
                <Route
                    path="/establishments/list"
                    render={(props) => <EstablishmentList {...props} />}
                />
                <Route
                    path="/establishment/form/:id"
                    render={(props) => <EstablishmentForm {...props} />}
                />
                <Route
                    path="/establishment/form"
                    render={(props) => <EstablishmentForm {...props} />}
                />
                <Route exact path="/administrators">
                    <Redirect to="/administrators/list" />
                </Route>
                <Route
                    path="/administrators/list"
                    render={(props) => <AdministratorList {...props} />}
                />
                <Route
                    exact
                    path="/administrators/form"
                    render={(props) => <AdministratorForm {...props} />}
                />
                <Route
                    path="/administrators/form/:id"
                    render={(props) => <AdministratorFormEdit {...props} />}
                />

                <Route exact path="/invoices">
                    return <Redirect to="/invoices/list" />
                </Route>
                <Route path="/invoices/list" render={(props) => <InvoicesList {...props} />} />

                <Route path="/invoices/form" render={(props) => <InvoicesForm {...props} />} />

                <Route exact path="/invoices/:id" render={(props) => <InvoicesShow {...props} />} />

                <Route path="/user/create" render={(props) => <Faqs {...props} />} />
                <Route
                    exact
                    path="/payment/expired"
                    render={(props) => <ExpiredInvoice {...props} />}
                ></Route>
                <Route
                    exact
                    path="/invoices/pay/:token"
                    render={(props) => <PayInvoice {...props} />}
                ></Route>
                <Route
                    exact
                    path="/payment/status"
                    render={(props) => <StatusInvoice {...props} />}
                ></Route>
                <Route
                    exact
                    path="/invoices/withoutamount/:hash"
                    render={(props) => <PayWithoutAmount {...props} />}
                ></Route>
                <Route render={(props) => <NotFound {...props} />} />
            </Switch>
        </Router>
    </React.Suspense>
)

export default Routes

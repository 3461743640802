import API from '../Api'

class IdentifactionTypeService {
    static get(auth) {
        return API({
            method: 'GET',
            url: '/identification-types',
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }
}

export default IdentifactionTypeService

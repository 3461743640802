import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '../Icon'
import { Card } from '../../assets'
import {
    SecondaryCardWrapper,
    PrimaryCardWrapper,
    InformationCardWrapper,
    ActionCardWrapper,
    StepsCardWrapper
} from './style'
import CustomButton from '../CustomButton'

export const PrimaryCard = ({ children, ...props }) => (
    <PrimaryCardWrapper {...props}>{children}</PrimaryCardWrapper>
)

export const SecondaryCard = ({ children, ...props }) => (
    <SecondaryCardWrapper {...props}>{children}</SecondaryCardWrapper>
)

export const InformationCard = ({ children, ...props }) => (
    <InformationCardWrapper {...props}>{children}</InformationCardWrapper>
)
export const StepsCard = ({ children, ...props }) => (
    <StepsCardWrapper {...props}>{children}</StepsCardWrapper>
)

export const ActionCard = ({
    active,
    children,
    title,
    icon,
    hasSeparator,
    centerAlign,
    dropdown,
    hasBack,
    backLink,
    setShowDropdow,
    showDropdow,
    isMobile,
    backgroundColor,
    ...props
}) => (
    <ActionCardWrapper
        {...props}
        className="row"
        active={active}
        centerAlign={centerAlign}
        open={showDropdow}
        backgroundColor={backgroundColor}
        icon={icon}
    >
        <div className="wrapper-container">
            {icon && Card.hasIcon && (
                <div className="icon-container">
                    <Icon size="32px" icon={icon} />
                </div>
            )}
            <div className="title-container">
                <h1>{title}</h1>
            </div>
            {hasSeparator && <div className="separator d-none d-sm-none d-md-block" />}
            <div className="btn-container">{children}</div>
            {hasBack && (
                <CustomButton
                    className="d-md-none"
                    buttonType="link"
                    category="auxiliar"
                    icon="izquierda"
                    onClick={backLink}
                ></CustomButton>
            )}
            {dropdown ? (
                <div className="dropdown">
                    <a
                        onClick={() => setShowDropdow(!showDropdow)}
                        className={isMobile ? 'icon-mobile' : 'btn btn-primary dropdown-toggle'}
                        role="button"
                    >
                        {isMobile && (
                            <>
                                <div className="aux-background"></div>
                                <i className="icon-Menu" />
                            </>
                        )}
                    </a>
                    <div
                        className="dropdown-menu"
                        style={{ display: showDropdow ? 'grid' : 'none' }}
                    >
                        {children}
                    </div>
                </div>
            ) : (
                <div className={dropdown ? 'btn-container-movil-aux' : 'btn-container-movil'}>
                    {children}
                </div>
            )}
        </div>
    </ActionCardWrapper>
)

ActionCard.propTypes = {
    children: PropTypes.node,
    active: PropTypes.bool,
    hasSeparator: PropTypes.bool,
    title: PropTypes.string,
    icon: PropTypes.string,
    isMobile: PropTypes.bool,
    centerAlign: PropTypes.bool
}

ActionCard.defaultProps = {
    children: '',
    active: false,
    hasSeparator: false,
    centerAlign: false,
    isMobile: false,
    title: '',
    icon: ''
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import '../assets/css/font.css'
import '../assets/css/global.css'
import styled from 'styled-components'
import BlockUi from 'react-block-ui'
import 'react-block-ui/style.css'
import Logo from '../components/Logo'
import NewFooter from '../components/NewFooter'

import { ProjectName } from '../assets'

const ContainerOutSession = styled.div`
    overflow-x: hidden;
    margin-bottom: 24px;
`

const StyledContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const LogoHeader = styled.div`
    margin-top: 25px;
    flex-direction: row;
`

const Public2 = ({ children, blocking, subTitle }) => {
    const mainPanel = React.useRef(null)
    const location = useLocation()

    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        mainPanel.current.scrollTop = 0
        if (
            window.innerWidth < 993 &&
            document.documentElement.className.indexOf('nav-open') !== -1
        ) {
            document.documentElement.classList.toggle('nav-open')
            const element = document.getElementById('bodyClick')
            element.parentNode.removeChild(element)
        }
    }, [location])

    return (
        <div id="page-wrap-public2" className="wrapper">
            <BlockUi tag="div" blocking={blocking}>
                <div className="main-panel" ref={mainPanel}>
                    <div className="container-fluid">
                        <LogoHeader className="col-12 col-md-8 col-xl-8 col-xs-6 offset-md-2 offset-xl-2 offset-xs-3">
                            <div className="row">
                                <div className="col-4">
                                    <Logo logoType={'header'} />
                                </div>
                                <div className="col-8" style={{ textAlign: 'right' }}>
                                    <p>
                                        {ProjectName}{' '}
                                        <span style={{ fontWeight: 700, fontSize: 12 }}>
                                            | {subTitle}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </LogoHeader>
                        <ContainerOutSession className="col-12">
                            <StyledContainer className="container">{children}</StyledContainer>
                        </ContainerOutSession>
                    </div>
                    <NewFooter text={ProjectName} />
                </div>
            </BlockUi>
        </div>
    )
}

Public2.propTypes = {
    blocking: PropTypes.bool,
    showMenuBar: PropTypes.bool,
    title: PropTypes.string,
    showMenu: PropTypes.bool
}

Public2.defaultProps = {
    blocking: false,
    showMenuBar: true,
    showMenu: true
}

export default Public2

import React from 'react'
import Header from '../../components/Header'

import { Container, StyledLink, StyledTitle, Wrapper, IconImage, PrimaryCard } from './style'
import iconMobile from '../../assets/images/icon-mobile.svg'

const ExpiredInvoice = () => {
    return (
        <Container>
            <Header />
            <PrimaryCard>
                <Wrapper>
                    <IconImage src={iconMobile} />
                    <StyledTitle>Estimado cliente, su sesión de pago ha caducado.</StyledTitle>

                    <StyledLink>
                        Por favor, póngase en contacto con el establecimiento donde realizó la
                        compra, para <span>solicitar el envío de un nuevo link de pagos.</span>
                    </StyledLink>
                </Wrapper>
            </PrimaryCard>
        </Container>
    )
}

export default ExpiredInvoice

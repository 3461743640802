import React, {useContext, useEffect} from 'react'
import { StatusImage, StatusWrapper, StatusContainer, StatusTitle, StatusText, StatusButton } from '../style'
import GeneralContext from '../../../context/GeneralContext'
import check from '../../../assets/images/check.svg'
import iconPending from '../../../assets/images/icon-pending.svg'
import iconRejected from '../../../assets/images/icon-rejected.svg'

const NewContent = ({ invoice, statusParam }) => {

    const { displayToast } = useContext(GeneralContext)

    const updateTransaction = () => {
        try {
            console.log('Updating transaction..');
            window.location.reload();
        } catch (error) {
            displayToast({
                type: 'fail',
                message: error.response?.data?.message
            })
        }
    }

    const goToPay = () => {
        try {
            window.location.replace('/invoices/pay/' + invoice?.public_token)
        } catch (error) {
            displayToast({
                type: 'fail',
                message: error.response?.data?.message
            })
        }
    }

    let iconStatus =  '';
    let titleStatus =  '';
    let descriptionStatus1 =  '';
    let descriptionStatus2 =  '';
    let textButton = '';
    let actionFunction = null;

    if (invoice){
        if (statusParam === 'APPROVED'){
            iconStatus = check;
            titleStatus = 'Pago aprobado';
            descriptionStatus1 = 'Listo, tu pago se realizó exitosamente.';
            descriptionStatus2 = 'Hemos notificado al vendedor.';
            textButton = 'Ver detallde de pago';
        }else if (statusParam === 'PENDING'){
            iconStatus = iconPending;
            titleStatus = 'Pago pendiente';
            descriptionStatus1 = 'Por favor, espera 5 minutos mientras';
            descriptionStatus2 = 'verificamos el proceso de la transacción.';
            textButton = 'Actualizar transacción';
            actionFunction = updateTransaction;
        } else if (statusParam === 'REJECTED'){
            iconStatus = iconRejected;
            titleStatus = 'Pago rechazado';
            descriptionStatus1 = (window.localStorage.getItem('paymentMessage') ?? invoice?.reason ?? 'Transacción rechazada' ) + ', el pago no se pudo completar.';
            descriptionStatus2 = '¿Deseas realizar el pago nuevamente?';
            textButton = 'Si, realizar pago';
            actionFunction = goToPay;
        }
    }

    return (
        <StatusContainer className="col-12 col-md-8 col-xl-6 col-xs-6 offset-md-2 offset-xl-3 offset-xs-3">
            <div className="container-responsive">
                <StatusImage className="row">
                    <img src={iconStatus} className='image-responsive'></img>
                </StatusImage>
                <StatusWrapper>
                    <div className="text-center">
                        <StatusTitle>{titleStatus}</StatusTitle>
                        <StatusText>{descriptionStatus1}</StatusText>
                        <StatusText>{descriptionStatus2}</StatusText>
                    </div>
                    {invoice && statusParam != 'APPROVED' ? (
                        <StatusButton disabled={false} onClick={actionFunction}>
                            {textButton}
                        </StatusButton>
                    ) : (<></>)}
                </StatusWrapper>
            </div>
        </StatusContainer>
    )
}

export default NewContent
import React, { useEffect, useContext } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { InnerForm } from '../../../../components/Form/style'
import AuthContext from '../../../../context/AuthContext'
import { handleCode, handleCurrent, handleEmail } from './helpers'
import ContentFirstInput from './ContentFirstInput'

const schema = yup.object().shape({
    name: yup
        .string()
        .matches(/^[A-Za-záéíóúÁÉÍÓÚñÑ ]*$/, 'Solo se permiten letras')
        .required('Campo nombres requerido'),
    lastname: yup
        .string()
        .matches(/^[A-Za-záéíóúÁÉÍÓÚñÑ ]*$/, 'Solo se permiten letras')
        .required('Campo apellidos requerido'),
    code: yup
        .string()
        .matches(/^[A-Za-z0-9 _-]*$/, 'Solo números, letras y guiones')
        .required('Campo código requerido'),
    email: yup
        .string()
        .email('Formato de correo electrónico incorrecto')
        .required('Campo e-mail requerido')
})

const FirstStep = ({
    setActionForm,
    setRequestData,
    requestData,
    current,
    handleLoader,
    initialData,
    nextStep,
    errorRequest
}) => {
    const { token } = useContext(AuthContext)
    const { control, handleSubmit, errors, getValues, trigger, setError } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: {
            ...requestData
        },
        criteriaMode: 'firstError',
        shouldFocusError: true
    })

    const onSubmit = async (data) => {
        const validationEmail = await handleEmail(
            handleLoader,
            trigger,
            getValues,
            initialData,
            token,
            setError
        )
        if (validationEmail) {
            const validateCode = await handleCode(
                handleLoader,
                trigger,
                getValues,
                initialData,
                token,
                setError
            )
            if (validateCode) {
                setRequestData((prevData) => ({ ...prevData, ...data }))
                nextStep()
            }
        }
    }
    useEffect(() => {
        handleCurrent(current, setActionForm, handleSubmit, onSubmit, errorRequest, setError)
    }, [current, errorRequest])

    return (
        <InnerForm autoComplete="off" current={current}>
            <ContentFirstInput
                control={control}
                errors={errors}
                handleLoader={handleLoader}
                trigger={trigger}
                getValues={getValues}
                initialData={initialData}
                token={token}
                setError={setError}
            />
        </InnerForm>
    )
}

export default FirstStep

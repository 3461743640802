import React from 'react'
import { SupportMail } from '../../../assets'
import { Body, Button } from '../../../assets' 

const Contact = ({ showTitle }) => {
    return (
        <div className="container">
            <div>
                {showTitle ? (<p style={{fontSize: 16, fontWeight: 400}}>Contáctanos</p>) : (<></>)}
                {showTitle ? (<div className="copyright-text"></div>) : (<></>)}
                <p style={{fontSize: 12}}><i style={{color: Button.primary.defaultColor.active.color}} className="icon-correo" /><span style={{color: Body.label.color}}>{SupportMail}</span></p>
                <p style={{fontSize: 12}}><i style={{color: Button.primary.defaultColor.active.color}} className="icon-direccion" /><span style={{color: Body.label.color}}>Edificio QPH Mallorca,<br/>Quito - Ecuador.</span></p>
            </div>
        </div>
    )
}

export default Contact
import CryptoJS from 'crypto-js'

const passphrase = process.env.REACT_APP_ENCRIPTION_PASSPHRASE
const iv = process.env.REACT_APP_ENCRIPTION_IV
const salt = process.env.REACT_APP_ENCRIPTION_SALT

const getKey = (passphrase, salt) => {
    const key = CryptoJS.PBKDF2(passphrase, salt, {
        hasher: CryptoJS.algo.SHA256,
        keySize: 64 / 8,
        iterations: 999
    })
    return key
}

export const encryptText = (plainText) => {
    const key = getKey(passphrase, salt)
    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
        iv: CryptoJS.enc.Utf8.parse(iv)
    })
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
}

export const decryptText = (encryptedText) => {
    const key = getKey(passphrase, salt)
    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
        iv: CryptoJS.enc.Utf8.parse(iv)
    })
    return decrypted.toString(CryptoJS.enc.Utf8)
}

export const maskText = (str) => {
    return ('' + str).slice(0, 3) + ('' + str).slice(3, str.length).replace(/./g, '*')
}

import API from './Api'

class CommonService {
    static ValidateEmail(auth, mail) {
        return API({
            method: 'GET',
            url: `validate/email?email=${mail}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static ValidateUser(auth, user) {
        return API({
            method: 'GET',
            url: `/duplicated/username?username=${user}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static ValidateRuc(auth, user) {
        return API({
            method: 'GET',
            url: `/validate/ruc?identification=${user}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static ValidateCI(auth, user) {
        return API({
            method: 'GET',
            url: `/validate/ci?identification=${user}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }
}
export default CommonService

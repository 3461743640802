import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Body, FontSizeTitle } from '../../assets'

const TitleWrapper = styled.div`
    justify-content: ${(props) => (props.align ? `${props.align}` : 'none')};
    h1 {
        font-size: ${FontSizeTitle};
        font-weight: bold;
        color: ${Body.title.color};
        margin-top: 1.5rem;
        @media screen and (max-width: 768px) {
            font-size: 1rem;
        }
    }
`

const Title = ({ children, border, size, align }) => (
    <TitleWrapper border={border} align={align} className="row">
        <div className="col-12">
            <h1 className={size}>{children}</h1>
        </div>
    </TitleWrapper>
)

Title.propTypes = {
    children: PropTypes.string,
    border: PropTypes.bool,
    size: PropTypes.oneOf(['xs', 'sm', 'lg'])
}

Title.defaultProps = {
    children: 'Título',
    border: false,
    size: 'lg'
}
export default Title

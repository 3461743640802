import React, { useContext, useState, useEffect, useMemo } from 'react'
import Public2 from '../../layouts/public2'
import InvoiceService from '../../services/Invoice'
import GeneralContext from '../../context/GeneralContext'
import NewContent from './components/NewContent'
import { handleStatusParam } from './helpers'

const paymentStatus = {
    PENDING: 'PENDIENTE',
    APPROVED: 'APROBADO',
    REJECTED: 'RECHAZADO'
}

const StatusInvoice = ({ location }) => {
    const { displayToast } = useContext(GeneralContext)
    const [isScreenLocked, setIsScreenLocked] = useState(true)
    const [isCanceled, setIsCanceled] = useState(false)
    const [isPending, setIsPending] = useState(false)
    const [invoice, setInvoice] = useState()
    const [isEmptyParam, setIsEmptyParam] = useState(false)
    const requestID = window.localStorage.getItem('requestID') ?? ''

    const statusParam = useMemo(() => {
        const query = new URLSearchParams(location.search)
        const statusP = query.get('paymentStatus')
        return handleStatusParam(statusP, invoice)
    }, [isEmptyParam])

    const getInvoice = async () => {
        try {
            const response = await InvoiceService.getByRequestID(requestID)
            setInvoice(response.data.data)
            setIsScreenLocked(false)
        } catch (error) {
            displayToast({
                type: 'info',
                message: error.response?.data?.message
            })
            setIsScreenLocked(false)
            setInvoice(error.response?.data?.errors)
        }
    }

    useEffect(() => {
        getInvoice()
    }, [])

    useEffect(() => {
        if (
            statusParam &&
            statusParam === 'PENDING' &&
            invoice &&
            invoice?.transaction?.transaction_status !== statusParam
        ) {
            //When transaction status is pending and is different from queryString removeIt if BREAKS
            if (invoice?.transaction?.transaction_status !== 'REJECTED') {
                setIsCanceled(false)
            }

            if (invoice?.transaction?.transaction_status !== 'PENDING') {
                setIsPending(false)
            }
            setIsEmptyParam(true)
            return
        }

        if (statusParam === 'REJECTED') {
            setIsCanceled(true)
        } else if (statusParam === 'PENDING') {
            setIsPending(true)
        }

        if (!statusParam && invoice) {
            //SAFARI || Iphone => no queryString
            setIsEmptyParam(true)
        }
    }, [invoice])

    return (
        <Public2 subTitle={'Resumen de pago'}>
            <div className="row">
                <NewContent
                    invoice={invoice}
                    statusParam={statusParam}
                />
            </div>
        </Public2>
    )
}

export default StatusInvoice

import styled from 'styled-components'
import { Chip } from '../../assets'

export const ContainerChip = styled.div`
    border: solid 0.5px ${Chip.border};
    padding: ${Chip.padding};
    border-radius: ${Chip.borderRadius};
    width: fit-content;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: ${Chip.color};
    margin: 0 15px;
    box-shadow: ${Chip.shadow};
    background: #ffffff;
    @media screen and (max-width: 992px) {
        margin: 5px 0;
        justify-content: start;
        padding: 10px;
    }
`
export const TitleChip = styled.div`
    font-weight: bold;
    margin-right: 5px;
`
export const InfoChip = styled.div`
    font-weight: 300;
    color: ${(props) => (props.colorText ? props.colorText : Chip.color)};
    font-weight: ${(props) => (props.colorText ? 'bold' : 'initial')};
`

import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const TitleWrapper = styled.div`
    align-items: center;
    background-image: url('https://evertec-ec.s3.us-east-2.amazonaws.com/login.jpg');
    color: #ffffff;
    display: flex;
    flex-direction: column;
    font-size: 21px;
    height: 33vh;
    justify-content: space-between;
    padding: 0 0 45px;
    text-align: center;
    width: 100%;
`
export const CloseButton = styled(Link)`
    font-size: 2.5rem;
    color: #ffffff;
`

import API from '../Api'

class InvoiceService {
    static List({
        token,
        limit,
        offset,
        order,
        startDate,
        endDate,
        status,
        sellerID,
        establishmentID
    }) {
        return API({
            method: 'GET',
            url: `/invoices?limit=${limit}&trashed=true${offset}${order}${startDate}${endDate}${status}${sellerID}${establishmentID}`,
            headers: token && { Authorization: `Bearer ${token}` }
        })
    }

    static Filter(auth, value, limit, sellerID, establishmentID) {
        return API({
            method: 'GET',
            url: `/invoices?filter=${value}&limit=${limit}&trashed=true${sellerID}${establishmentID}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static getById(auth, id) {
        return API({
            method: 'GET',
            url: `/invoices/${id}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static getByToken(token) {
        return API({
            method: 'GET',
            url: `/invoices/pay/${token}`
        })
    }

    static getByRequestID(requestID) {
        return API({
            method: 'GET',
            url: `/payment/status/${requestID}`
        })
    }

    static CreateURLPayment(invoiceID) {
        return API({
            method: 'POST',
            url: '/invoices/generateP2PURL',
            data: { invoice_id: invoiceID }
        })
    }

    static Create(auth, data) {
        return API({
            method: 'POST',
            url: '/invoices',
            headers: auth && { Authorization: `Bearer ${auth}` },
            data
        })
    }

    static UpdateStatus(data) {
        return API({
            method: 'PUT',
            url: `/invoices/${data.id}`,
            data
        })
    }

    static Cancel(auth, id, reason) {
        return API({
            method: 'PUT',
            url: `/invoices/cancel/${id}`,
            headers: auth && { Authorization: `Bearer ${auth}` },
            data: { reason }
        })
    }

    static Activate(auth, id) {
        return API({
            method: 'PUT',
            url: `/invoices/activate/${id}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static CheckServiceTax(auth) {
        return API({
            method: 'GET',
            url: `/commerce/checkServices`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static CheckInternalReference(auth, value) {
        return API({
            method: 'GET',
            url: `/invoices/check-invoice/${value}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static UploadFiles(auth, data) {
        return API({
            method: 'POST',
            url: `/upload-files`,
            headers: auth && { Authorization: `Bearer ${auth}` },
            data
        })
    }

    static ForwardInvoice(auth, token) {
        return API({
            method: 'GET',
            url: `seller/forward/${token}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static ExportInvoices({ token, status, startDate, endDate, selID, estID }) {
        return API({
            method: 'GET',
            url: `invoices/export?${status}${startDate}${endDate}${selID}${estID}`,
            headers: token && {
                Authorization: `Bearer ${token}`
            },
            responseType: 'blob'
        })
    }

    static getHistory(limit, offset, order) {
        return API({
            method: 'GET',
            url: `/invoices/history?limit=${limit}&offset=${offset}&trashed=true&${order}`
        })
    }

    static getEstablishmentByHash(hash) {
        return API({
            method: 'GET',
            url: `/invoices/minify/getEstablishment/${hash}`
        })
    }

    static createWithoutAmount(data) {
        return API({
            method: 'POST',
            url: 'invoices/minify/withoutAmount',
            data
        })
    }

    static NotificationUrl(data, type) {
        return API({
            method: 'POST',
            url: type === 'minify' ? '/invoices/minify/notification' : '/invoices/notification',
            data
        })
    }
}

export default InvoiceService

import styled from 'styled-components'
import { PayPage } from '../../assets'

export const Container = styled.div`
    height: 90vh;
`
export const Wrapper = styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 39rem;
    padding: 3.5rem 6rem;
    background-color: #ffffff;
    box-shadow: 0px 0px 14px 15px rgba(101, 101, 101, 0.06);
    border-radius: 0.5rem;

    @media screen and (max-width: 768px) {
        padding: 3.5rem;
    }
`
export const PrimaryCard = styled.div`
    height 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const StyledLink = styled.h3`
    font-size: 0.75rem;
    font-weight: 300;
    color: ${PayPage.value};
    text-align: center;
    margin-bottom: 2rem;
    line-height: 1rem;
    span {
        font-weight: 500;
    }
`
export const StyledTitle = styled.h3`
    color: #001151;
    font-size: 1.25rem;
    font-weight: 500;
    padding: 1rem 0;
    text-align: center;
`

export const IconImage = styled.img`
    width: 10rem;
`

import React from 'react'
import PropTypes from 'prop-types'
import { ContainerChip, TitleChip, InfoChip } from './style'

const Chip = ({ title, info, colorText, isBold }) => (
    <ContainerChip>
        {title && <TitleChip>{title}:</TitleChip>}
        <InfoChip colorText={colorText}>{isBold ? <b>{info}</b> : info}</InfoChip>
    </ContainerChip>
)

Chip.propTypes = {
    title: PropTypes.string,
    info: PropTypes.string,
    colorText: PropTypes.string,
    isBold: PropTypes.bool
}

Chip.defaultProps = {
    title: '',
    info: '',
    colorText: '',
    isBold: false
}

export default Chip

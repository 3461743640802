import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router'
import Public2 from '../../layouts/public2'
import InvoiceService from '../../services/Invoice'
import GeneralContext from '../../context/GeneralContext'
import { Helmet } from 'react-helmet'
import validator from 'validator'
import DesktopView from './components/DesktopView'
import { PaymentContainer, Container } from './style'

const PayInvoice = () => {
    const { token } = useParams()
    const { displayToast } = useContext(GeneralContext)
    const [isScreenLocked, setIsScreenLocked] = useState(true)
    const [hasError, setHasError] = useState(false)
    const [acceptTYC, setAcceptTYC] = useState(false)
    const [acceptPP, setAcceptPP] = useState(false)
    const [invoice, setInvoice] = useState()
    //--- isMobile ---
    const [width, setWidth] = useState(window.innerWidth)
    const [isMobile, setIsMobile] = useState(false)

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth)
    }
    useEffect(() => {
        setIsMobile(width <= 768)
    }, [width])
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])
    //--- end isMobile ---

    const addListenerPtoP = () => {
        try {
            window.P.on('response', async (o) => {
                try {
                    if (o.status.status) {
                        const invoiceTypeI = [0, 1]
                        const invoiceTypeII = [2, 3]

                        const data = {
                            status: {
                                status: o.status.status,
                                message: o.status.message,
                                reason: o.status.reason,
                                date: o.status.date
                            },
                            requestId: o.requestId,
                            reference: o.reference,
                            signature: o.signature
                        }

                        if (invoiceTypeI.includes(invoice.invoice_api)) {
                            //run main notification
                            notificationURL(data, 'main')
                        } else if (invoiceTypeII.includes(invoice.invoice_api)) {
                            //run secondary notification for app
                            notificationURL(data, 'minify')
                        }

                        setTimeout(() => {
                            window.localStorage.setItem('paymentMessage', o.status.message)
                            window.location.replace(
                                `/payment/status?paymentStatus=${o.status.status}`
                            )
                        }, 2000)
                    }
                } catch (error) {
                    setIsScreenLocked(false)
                    displayToast({
                        type: 'fail',
                        message: error.response?.data?.message
                    })
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const requestURL = async () => {
        setIsScreenLocked(true)
        try {
            const response = await InvoiceService.CreateURLPayment(invoice.id)
            setIsScreenLocked(false)
            if (response) {
                const requestID = response.data.data.requestID
                const ptopUrl = response.data?.data?.processUrl
                window.localStorage.setItem('requestID', requestID)
                window.location.replace(ptopUrl)
                //window.P.init(response.data?.data?.processUrl, { opacity: 0.4 })
                addListenerPtoP()
            }
        } catch (error) {
            setIsScreenLocked(false)
            displayToast({
                type: 'fail',
                message: error.response?.data?.message
            })
        }
    }

    const getInvoice = async () => {
        try {
            const response = await InvoiceService.getByToken(token)
            setInvoice(response.data.data)
            window.localStorage.setItem('invoiceToken', token)
            setIsScreenLocked(false)
        } catch (error) {
            displayToast({
                type: 'fail',
                message: error.response?.data?.message
            })
            setIsScreenLocked(false)
            setHasError(true)
            window.localStorage.setItem('invoiceToken', token)
            setInvoice(error.response?.data?.errors)
        }
    }

    const reloadPage = () => {
        const { href, hostname, pathname } = window.location
        const arrayPath = pathname.split('/')
        console.log(arrayPath[1] === 'invoices')
        if (hostname === 'localhost' && arrayPath[1] === 'invoices' && arrayPath[2] === 'pay') {
            window.location.replace(encodeURI(href))
        } else {
            if (validator.isURL(href)) {
                window.location.replace(encodeURI(href))
            }
        }
    }

    useEffect(() => {
        getInvoice()
    }, [])

    const notificationURL = async (data, type) => {
        setIsScreenLocked(true)
        try {
            await InvoiceService.NotificationUrl(data, type)
            setIsScreenLocked(false)
        } catch (error) {
            setIsScreenLocked(false)
            displayToast({
                type: 'fail',
                message: error.response?.data?.message
            })
        }
    }

    return (
        <Public2 subTitle={'Pagos'}>
            <div className="row">
                <Helmet>
                    <script src={process.env.REACT_APP_P2P_URL} async={true} defer={true}></script>
                </Helmet>
                <PaymentContainer className="col-12 col-md-8 col-lg-8 col-xl-6 col-xs-8 offset-lg-2 offset-md-2 offset-xl-3 offset-xs-2">
                    <DesktopView
                        invoice={invoice}
                        hasError={hasError}
                        acceptTYC={acceptTYC}
                        acceptPP={acceptPP}
                        requestURL={requestURL}
                        setAcceptTYC={setAcceptTYC}
                        setAcceptPP={setAcceptPP}
                        reloadPage={reloadPage}
                        isMobile={isMobile}
                    />
                    
                </PaymentContainer>
            </div>
        </Public2>
    )
}

export default PayInvoice

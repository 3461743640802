import React from 'react'
import { FooterWrapper, Copyright } from '../style'
import AccordionFooter from '../../../components/AccordionFooter'
import Contact from './Contact'
import HelpPage from './Help'
import Logo from '../../Logo'

const MobileFooter = ({ text }) => {
    const currentYear = new Date().getFullYear()
    const footerItems = [
        {
            name: 'contact',
            title: 'Contáctanos'
        },
        {
            name: 'faqs',
            title: 'Páginas de ayuda'
        }
    ]

    return (
        <FooterWrapper>
            <Copyright>
                <div className="copyright-text"></div>
                <div className="container">
                    <div className="col-4 my-4">
                        <Logo logoType={'header'} />
                    </div>
                </div>
                {footerItems.map((item, key) => (
                    <AccordionFooter
                        key={key}
                        title={item.title}
                        itemName={item.name}
                        itemContent={
                            item.name === 'contact' ? (
                                <Contact showTitle={false} />
                            ) : (
                                <HelpPage showTitle={false} />
                            )
                        }
                    />
                ))}
                <div className="terms-conditions" style={{ fontSize: 11 }}>
                    Copyright {currentYear}. Todos los derechos reservados {text.toUpperCase()} ©
                </div>
            </Copyright>
        </FooterWrapper>
    )
}

export default MobileFooter

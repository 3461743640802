import axios from 'axios'

const BAD_REQUEST = 400
const EXPIRED_TOKEN = 'Expired token'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
})

axiosInstance.interceptors.request.use(
    (config) => config,
    (error) => {
        console.log(`Interceptor Request Error${error}`)
    }
)

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === BAD_REQUEST) {
            if (error.response.data.error && error.response.data.message === EXPIRED_TOKEN) {
                window.localStorage.setItem('sessionExpired', 1)
                window.location = '/login'
            }
        }
        return Promise.reject(error)
    }
)
export default axiosInstance

import CommonService from '../../../../services/Common'
import EstablishmentService from '../../../../services/Establishment'
import SellerService from '../../../../services/Seller'

export const handleCurrent = (
    current,
    setActionForm,
    handleSubmit,
    onSubmit,
    errorRequest,
    setError
) => {
    if (current) {
        setActionForm(() => handleSubmit(onSubmit))
    }
    if (errorRequest) {
        if (errorRequest.code) {
            setError('code', {
                type: 'other',
                message: errorRequest.code[0]
            })
        }
        if (errorRequest.identification) {
            setError('identification', {
                type: 'other',
                message: errorRequest.identification[0]
            })
        }
    }
}
export const handleEmail = async (
    handleLoader,
    trigger,
    getValues,
    initialData,
    token,
    setError
) => {
    handleLoader(true)
    const result = await trigger('email')
    const emailVal = getValues('email')

    if (result) {
        if (emailVal !== initialData?.email) {
            try {
                const response = await CommonService.ValidateEmail(token, emailVal)
                handleLoader(false)
                if (response) {
                    return true
                }
            } catch (err) {
                if (err && err.response) {
                    setError('email', {
                        type: 'other',
                        message: err.response?.data?.errors?.email[0]
                    })
                } else {
                    setError('email', {
                        type: 'other',
                        message: 'Error al validar correo electrónico'
                    })
                }
            }
            handleLoader(false)
            return false
        }
        handleLoader(false)
        return true
    }
    handleLoader(false)
    return false
}
export const handleCode = async (
    handleLoader,
    trigger,
    getValues,
    initialData,
    token,
    setError
) => {
    handleLoader(true)
    const result = await trigger('code')
    const codeVal = getValues('code')
    if (result) {
        if (codeVal !== initialData?.code) {
            try {
                const response = await SellerService.validateCode(token, codeVal)
                handleLoader(false)
                if (response) {
                    return true
                }
            } catch (err) {
                if (err && err.response) {
                    setError('code', {
                        type: 'other',
                        message: err.response?.data?.message
                    })
                } else {
                    setError('code', {
                        type: 'other',
                        message: 'Error al validar código de vendedor'
                    })
                }
            }
            handleLoader(false)
            return false
        }
        handleLoader(false)
        return true
    }
    handleLoader(false)
    return false
}
export const handleOnKeyDownNumber = async (e) => {
    console.log(e)
    const charCode = e.which ? e.which : e.keyCode
    if (
        !(
            (charCode >= 48 && charCode <= 57) ||
            (charCode >= 96 && charCode <= 105) ||
            (charCode >= 37 && charCode <= 40) ||
            charCode === 8 ||
            charCode === 9 ||
            charCode === 46
        )
    ) {
        e.preventDefault() // stop character from entering input
    }
}
export const validateIdentification = async (getValues, clearErrors, token, setError) => {
    const identificationTypeValue = getValues('identificationTypeID')
    clearErrors('identification')
    if (identificationTypeValue) {
        const identificationValue = getValues('identification')
        switch (identificationTypeValue.label.toLowerCase()) {
            case 'ci': {
                try {
                    const response = await CommonService.ValidateCI(token, identificationValue)
                    return response
                } catch (error) {
                    setError('identification', {
                        type: 'other',
                        message:
                            error.response?.data?.errors?.identification ??
                            'Error al validar identificación'
                    })
                }
                break
            }
            case 'ruc': {
                try {
                    const response = await CommonService.ValidateRuc(token, identificationValue)
                    return response
                } catch (error) {
                    setError('identification', {
                        type: 'other',
                        message:
                            error.response?.data?.errors?.identification ??
                            'Error al validar identificación'
                    })
                }
                break
            }
            default: {
                return true
            }
        }
    }
    return false
}
export const fillEstablishments = async (
    optionsEstablishments,
    token,
    setOptionsEstablishments
) => {
    if (optionsEstablishments.length === 0) {
        const response = await EstablishmentService.ListToSelect(token)
        if (response) {
            setOptionsEstablishments(
                response.data.data.map((establishment) => ({
                    value: establishment.id,
                    label: establishment.name
                }))
            )
        }
    }
}

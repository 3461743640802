import styled from 'styled-components'
import { Button, Input } from '../../assets'

const ButtonComponent = styled.button`
    align-items: center;
    background: ${(props) => props.backgroundColor};
    border-width: ${(props) => (props.borderWidth ? props.borderWidth : '2px')};
    border-style: solid;
    border-color: ${(props) => props.borderColor};
    border-radius: ${Button.borderRadius};
    color: ${(props) => props.color};
    display: flex;
    font-size: ${(props) => (props.fontSize ? props.fontSize : '1em')};
    font-weight: ${Button.fontWeight};
    height: ${(props) => (props.height ? props.height : '50px')};
    justify-content: ${(props) => (props.isMenu ? 'start' : 'center')};
    padding: ${(props) => (props.padding ? props.padding : '0.25em 1em')};
    text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
    width: ${(props) => (props.isMenu ? 'max-content' : '100%')};

    :hover {
        background: ${(props) => props.hoverBackground};
        color: ${(props) => props.hoverColor};
                border-color: ${(props) => props.hoverBorder};
        text-decoration: none;
    }
    :active {
        background: ${(props) => props.activeBackground};
        color: ${(props) => props.activeColor};
        border: 2px solid ${(props) => props.activeBorder};
    }
    :disabled {
        background: ${(props) => props.disableBackground};
        color: ${(props) => props.disableColor};
        border: 1px solid ${(props) => props.disableBorder};
    }
    .auxiliar{
        background-color: #ffffff;
    }

    i {
        font-size: 1.3em;
        vertical-align: middle;
        @media screen and (max-width: 767px) {
            font-size: ${(props) => (props.isMenu ? '1.3em' : '2em')};
            padding-right: ${(props) => (props.isMenu ? '15px' : '0')};

        }
    }
    i.red{
        color:#ff4445;
    }
    i.success-fill{
        background-color: ${Button.primary.successColor.backgroundColor}
        border-radius: 10px;
        font-size: 32px;
        margin-left: 0.5rem;
        padding: 0;

    }
    &.tab-footer-right{
        border-radius: 0 ${Button.borderRadius} ${Button.borderRadius} 0 !important;
    }
    &.tab-footer-left{
        border-radius: ${Button.borderRadius}  0 0 ${Button.borderRadius} !important;
    }
    .gray-text{
        color:${Input.placeholder}
    }
    @media screen and (max-width: 767px) {
        .hide-movil {
            display: none;
        }
    }
    @media screen and (min-width: 767px) {
        justify-content: center;
         
    }
`

export default ButtonComponent

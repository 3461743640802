import React from 'react'
import CustomButton from '../../../../components/CustomButton'
import { PrimaryCard } from '../../../../components/CustomCard'
import {
    CircleActive,
    CircleInactive,
    CircleWrapper,
    TabFooter
} from '../../../Commerce/Form/style'
import { TabWrapper } from '../style'

const Content = ({
    requestData,
    initialData,
    steps,
    currentStep,
    setCurrentStep,
    setActionForm,
    setRequestData,
    setShowLoader,
    sendData,
    errorRequest,
    actionForm,
    isMobile
}) => {
    return (
        <>
            <PrimaryCard>
                <TabWrapper className={isMobile ? 'px-3' : ''}>
                    {requestData &&
                        initialData &&
                        steps.map((Step, index) => (
                            <Step
                                key={`step-${index}`}
                                current={currentStep === index}
                                nextStep={() => setCurrentStep(currentStep + 1)}
                                prevStep={() => setCurrentStep(currentStep - 1)}
                                setActionForm={setActionForm}
                                setRequestData={setRequestData}
                                requestData={requestData}
                                handleLoader={setShowLoader}
                                initialData={initialData}
                                sendData={sendData}
                                errorRequest={errorRequest}
                            />
                        ))}
                </TabWrapper>
                {!isMobile && (
                    <TabFooter className="row">
                        <div className="col-2">
                            {currentStep > 0 && (
                                <CustomButton
                                    buttonType="tertiary"
                                    iconPosition="left"
                                    onClick={() => setCurrentStep(currentStep - 1)}
                                    block={!(currentStep > 0 && currentStep < steps.length)}
                                    icon="izquierda"
                                >
                                    Anterior
                                </CustomButton>
                            )}
                        </div>
                        <div className="col-8">
                            <CircleWrapper>
                                {steps.map((_, index) =>
                                    currentStep === index ? (
                                        <CircleActive key={`step-${index}`} />
                                    ) : (
                                        <CircleInactive key={`step-${index}`} />
                                    )
                                )}
                            </CircleWrapper>
                        </div>
                        <div className="col-2">
                            <CustomButton
                                buttonType="primary"
                                iconPosition="right"
                                onClick={actionForm}
                                category={currentStep + 1 === steps.length ? 'success' : 'default'}
                                icon="derecha"
                                hasIcon
                            >
                                {currentStep + 1 === steps.length ? 'Guardar' : 'Siguiente'}
                            </CustomButton>
                        </div>
                    </TabFooter>
                )}
            </PrimaryCard>
            {isMobile && (
                <TabFooter className="row">
                    <div>
                        <CircleWrapper>
                            {steps.map((_, index) =>
                                currentStep === index ? (
                                    <CircleActive key={`step-${index}`} />
                                ) : (
                                    <CircleInactive key={`step-${index}`} />
                                )
                            )}
                        </CircleWrapper>
                    </div>
                    <div className="d-flex justify-content-bettween">
                        {currentStep > 0 ? (
                            <CustomButton
                                className="tab-footer-left"
                                buttonType="tertiary"
                                iconPosition="left"
                                onClick={() => setCurrentStep(currentStep - 1)}
                                block={!(currentStep > 0 && currentStep < steps.length)}
                                icon="izquierda"
                            >
                                Anterior
                            </CustomButton>
                        ) : (
                            <CustomButton
                                className="tab-footer-left"
                                block
                                buttonType="tertiary"
                                icon="izquierda"
                            >
                                Anterior
                            </CustomButton>
                        )}

                        <CustomButton
                            className="tab-footer-right"
                            buttonType="primary"
                            iconPosition="right"
                            onClick={actionForm}
                            category={currentStep + 1 === steps.length ? 'success' : 'default'}
                            icon="derecha"
                            hasIcon={currentStep + 1 === steps.length ? false : true}
                        >
                            {currentStep + 1 === steps.length ? 'Guardar' : 'Siguiente'}
                        </CustomButton>
                    </div>
                </TabFooter>
            )}
        </>
    )
}

export default Content

import API from '../Api'

class SellerService {
    static List(auth, limit, offset, order) {
        return API({
            method: 'GET',
            url: `/sellers?limit=${limit}&offset=${offset}&trashed=true&${order}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static Filter(auth, value, limit) {
        return API({
            method: 'GET',
            url: `/sellers/code/${value}?limit=${limit}&trashed=true`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static getById(auth, id) {
        return API({
            method: 'GET',
            url: `/sellers/${id}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static validateCode(auth, code) {
        return API({
            method: 'GET',
            url: `/sellers/checkDuplicatedCode/${code}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static Save(auth, data) {
        return API({
            method: 'POST',
            url: '/sellers',
            headers: auth && { Authorization: `Bearer ${auth}` },
            data
        })
    }

    static Update(auth, data) {
        return API({
            method: 'PUT',
            url: `/sellers/${data.id}`,
            headers: auth && { Authorization: `Bearer ${auth}` },
            data
        })
    }

    static Delete(auth, id) {
        return API({
            method: 'DELETE',
            url: `/sellers/${id}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static Activate(auth, id) {
        return API({
            method: 'PUT',
            url: `/sellers/activate/${id}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }
    static Credentials(auth, id) {
        return API({
            method: 'PUT',
            url: `seller/generate-credentials/${id}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }
}

export default SellerService

import React, { useState, useEffect } from 'react'
import { LogoAsset } from '../../assets'
import { CustomLogo } from './style'

const Logo = ({ logoType }) => {
    const [style, setStyle] = useState({})
    const [src, setSrc] = useState({})

    useEffect(() => {
        switch (logoType) {
            case 'header':
                setSrc(LogoAsset.LogoHeader.src)
                setStyle({
                    ...LogoAsset.LogoHeader.styles
                })
                break
            case 'login':
                setSrc(LogoAsset.LogoLogin.src)
                setStyle({
                    ...LogoAsset.LogoLogin.styles
                })
                break
            default: {
                setSrc(LogoAsset.LogoHeader.src)
                setStyle({
                    ...LogoAsset.LogoHeader.styles
                })
                break
            }
        }
    }, [logoType])

    return <CustomLogo {...style} src={src} />
}

export default Logo

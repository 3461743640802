import styled from 'styled-components'
import { Body, FontSizeTitle, Card, BorderRadius, Button, Icon } from '../../assets'

export const PrimaryCardWrapper = styled.div`
    margin: 8px 0;
    padding: 0;
    border: 1px solid ${Body.primaryCard.borderColor};
    background-color: ${Body.primaryCard.backgroundColor};
    border-radius: ${Body.primaryCard.borderRadius};

    @media screen and (min-width: 767px) {
        box-shadow: none;
        margin: 20px 0;
    }
`

export const SecondaryCardWrapper = styled.div`
    margin: 20px 0;

    border: solid 0.5px ${Body.secondaryCard.borderColor};
    background-color: ${Body.secondaryCard.backgroundColor};
    border-radius: ${Body.secondaryCard.borderRadius};
`

export const InformationCardWrapper = styled.div`
    margin: 20px 0;
    border: solid 1px ${Body.informationCard.borderColor};
    background-color: ${Body.informationCard.backgroundColor};
    color: ${Body.informationCard.color};
    padding: 11px 30px;
    border-radius: ${Body.informationCard.borderRadius};
    span {
        font-weight: bold;
    }
`
export const StepsCardWrapper = styled.div`
    margin: 20px 0;
    background-color: ${Body.primaryCard.backgroundColor};
    border-radius: ${Body.primaryCard.borderRadius};
    box-shadow: 0 0 8px #e0e5eb;
`
export const ActionCardWrapper = styled.div`
    margin: 20px 0;
    width: 100%;
    border-radius: ${BorderRadius};
    align-items: center;
    border: solid 1px
        ${(props) => (props.active ? Card.active.borderColor : Card.inactive.borderColor)};
    background-color: ${(props) =>
        props.backgroundColor
            ? props.backgroundColor
            : props.active
            ? Card.active.backgroundColor
            : Card.inactive.backgroundColor};
    .wrapper-container {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: auto 1fr auto;
        grid-gap: 24px;
        align-items: center;
        padding: 12px 24px;
        .icon-container {
            i {
                color: ${(props) =>
                    props.active ? Card.active.icon.color : Card.inactive.icon.color};
                background-color: ${(props) =>
                    props.active
                        ? Card.active.icon.backgroundColor
                        : Card.inactive.icon.backgroundColor};
            }
        }
        h1 {
            font-weight: normal;
            font-size: ${FontSizeTitle};
            color: ${(props) => (props.active ? Card.active.color : Card.inactive.color)};
            margin: 0;
        }
        .separator {
            width: 1px;
            height: 30px;
            margin: 22px;
            background-color: ${(props) =>
                props.active ? Card.separator.active.color : Card.separator.inactive.color};
            background-color: ${Card.separator.color};
        }
    }
    .btn-container {
        display: flex;
        align-items: center;
        width: 100%;
        flex: 1;
        justify-content: flex-end;
        justify-content: ${(props) => (props.centerAlign ? 'center' : 'flex-end')};
        gap: 12px;
        button {
            width: 240px;
            max-width: 100%;
            margin: auto;
        }
    }
    .icon-mobile {
        color: ${(props) => (props.open ? Card.menuActive.color : Icon.backgroundColor)};
        font-size: 48px;
        line-height: 0;
        position: relative;
        width: 68px;
        height: 45px;
        display: block;
    }
    .icon-mobile .aux-background {
        position: absolute;
        width: 12px;
        height: 32px;
        background: ${(props) => (props.open ? 'transparent' : Card.active.color)};
        left: 28px;
        top: 8px;
    }
    .icon-mobile i {
        position: absolute;
        left: 0;
        right: 0;
    }
    .btn-container-movil-aux {
        button {
            color: #ffffff;
            display: flex;
            flex-direction: row-reverse;
            i {
                background: ${Button.link.successColor.active.color};
                border-radius: 5px;
                font-size: 24px;
                margin: 0 1rem;
                padding: 4px;
            }
            :hover {
                background: none;
                border: none;
                outline: none;
            }
        }
    }

    @media screen and (max-width: 768px) {
        background: ${(props) =>
            props.backgroundColor
                ? props.backgroundColor
                : props.active
                ? Card.active.backgroundColor
                : '#ffffff'};

        .wrapper-container {
            grid-gap: 8px;
            padding: ${(props) => (props.icon !== '' ? '12px' : '30px 12px')};
            h1 {
                font-size: 16px;
            }
            .btn-container {
                display: none;
            }
            .dropdown-menu {
                right: 15px;
                left: auto;
                padding: 0.5rem;
                grid-row-gap: 6px;
            }
            .hide-movil {
                display: none;
            }
        }
    }
    @media screen and (min-width: 768px) {
        .wrapper-container {
            .dropdown {
                display: none;
            }
            .btn-container-movil {
                display: none;
            }
        }
    }
`

import React from 'react'
import { Item, CustomLink, Separator } from '../style'
import { Sidebar } from '../../../assets'

const ItemSidebar = ({ active, url, icon, title, activeItem }) => {
    return (
        <Item>
            <CustomLink to={url}>
                <div className={`item-container ${activeItem === active && 'active'}`}>
                    {Sidebar.hasIcon && <i className={icon} />}
                    <div className="item-text">{title}</div>
                </div>
            </CustomLink>
            <Separator />
        </Item>
    )
}

export default ItemSidebar

import React, { useContext } from 'react'
import {
    SideWrapper,
    SeparatorRight,
    InfoContainer,
    InfoWrapper,
    SeparatorLeft,
    MenuContainer
} from './style'
import AuthContext from '../../context/AuthContext'
import userAccess from '../../userAccess'
import ItemSidebar from './ItemSidebar'
const Aside = ({ activeItem }) => {
    const { token, user } = useContext(AuthContext)
    return token && user ? (
        <SideWrapper className="col-lg-2">
            <MenuContainer>
                {token && user?.role?.id === userAccess.ADMIN.ACCESS_LEVEL ? (
                    <ItemSidebar
                        active={activeItem}
                        activeItem="commerces"
                        url="/commerces"
                        icon="icon-comercio"
                        title="Comercios"
                    />
                ) : (
                    <></>
                )}

                {(token && user?.role?.id === userAccess.COMMERCE.ACCESS_LEVEL) ||
                user?.role?.id === userAccess.ESTABLISHMENT.ACCESS_LEVEL ? (
                    <ItemSidebar
                        active={activeItem}
                        activeItem="establishments"
                        url="/establishments"
                        icon="icon-establecimiento"
                        title="Establecimientos"
                    />
                ) : (
                    <></>
                )}

                {(token && user?.role?.id === userAccess.COMMERCE.ACCESS_LEVEL) ||
                user?.role?.id === userAccess.ESTABLISHMENT.ACCESS_LEVEL ? (
                    <ItemSidebar
                        active={activeItem}
                        activeItem="sellers"
                        url="/sellers"
                        icon="icon-vendedores"
                        title="Vendedores"
                    />
                ) : (
                    <></>
                )}

                {token && user?.role?.id === userAccess.COMMERCE.ACCESS_LEVEL ? (
                    <ItemSidebar
                        active={activeItem}
                        activeItem="administrators"
                        url="/administrators"
                        icon="icon-admin"
                        title="Administradores"
                    />
                ) : (
                    <></>
                )}

                {token && user?.role?.id === userAccess.SELLER.ACCESS_LEVEL ? (
                    <ItemSidebar
                        active={activeItem}
                        activeItem="invoices"
                        url="/invoices/list"
                        icon="icon-admin"
                        title="Gestión de cobros"
                    />
                ) : (
                    <></>
                )}
            </MenuContainer>

            <InfoWrapper>
                <SeparatorRight />
                <InfoContainer>
                    Recibe <b>pagos en línea</b> de forma fácil y segura con cualquier tarjeta.
                </InfoContainer>
                <SeparatorLeft />
            </InfoWrapper>
        </SideWrapper>
    ) : (
        <></>
    )
}

export default Aside

import React from 'react'
import { Link } from 'react-router-dom'
import { Body } from '../../../assets'

const HelpPage = ({ showTitle }) => {
    return (
        <div className="container">
            <div>
                {showTitle ? (<p style={{fontSize: 16, fontWeight: 400}}>Páginas de ayuda</p>) : (<></>)}
                {showTitle ? (<div className="copyright-text"></div>) : (<></>)}
                <p style={{fontSize: 12}}>
                    <Link to="/" type="secondary" style={{color: Body.label.color}}>
                        Inicio
                    </Link>
                </p>
                <p style={{fontSize: 12}}>
                    <Link to="/faqs" type="secondary" style={{color: Body.label.color}}>
                        FAQs
                    </Link> 
                </p>
            </div>
        </div>
    )
}

export default HelpPage
import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import Helmet from 'react-helmet'
import '../assets/css/font.css'
import '../assets/css/global.css'
import styled from 'styled-components'
import BlockUi from 'react-block-ui'
import Header from '../components/Header'
import Sidebar from '../components/Sidebar'
import 'react-block-ui/style.css'
import AuthContext from '../context/AuthContext'
import Title from '../components/Title'

import { ProjectName, Favicon } from '../assets'

const Container = styled.div`
    overflow-x: hidden;
`
const ContainerOutSession = styled.div`
    overflow-x: hidden;
`

const StyledContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const Layout = ({ children, blocking, activeItem, showMenuBar, title, showMenu }) => {
    const { token, user } = useContext(AuthContext)

    const mainPanel = React.useRef(null)

    const location = useLocation()

    useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        mainPanel.current.scrollTop = 0
        if (
            window.innerWidth < 993 &&
            document.documentElement.className.indexOf('nav-open') !== -1
        ) {
            document.documentElement.classList.toggle('nav-open')
            const element = document.getElementById('bodyClick')
            element.parentNode.removeChild(element)
        }
    }, [location])

    return (
        <div id="page-wrap" className="wrapper">
            <Helmet
                title={ProjectName}
                meta={[
                    { name: 'description', content: ProjectName },
                    {
                        name: 'keywords',
                        content: 'prefacturas, pagos, comercios, establecimientos, vendedores'
                    },
                    {
                        name: 'viewport',
                        content:
                            'width=device-width, initial-scale=1.0, shrink-to-fit=no, maximum-scale=1, user-scalable=0'
                    }
                ]}
                link={[
                    {
                        rel: 'shortcut icon',
                        type: 'image/x-icon',
                        href: `${Favicon}`
                    }
                ]}
            />
            <BlockUi tag="div" blocking={blocking}>
                <div className="main-panel" ref={mainPanel}>
                    {showMenuBar && <Header activeItem={activeItem} showMenu={showMenu} />}
                    {!showMenuBar && <Title title={title} />}

                    <div className="container-fluid">
                        <div className="row">
                            <Sidebar activeItem={activeItem} />
                            {token && user ? (
                                <Container className="col-12 col-lg-10 p-0">
                                    <StyledContainer className="container-fluid">
                                        {children}
                                    </StyledContainer>
                                </Container>
                            ) : (
                                <ContainerOutSession className="col-12">
                                    <StyledContainer className="container">
                                        {children}
                                    </StyledContainer>
                                </ContainerOutSession>
                            )}
                        </div>
                    </div>
                    {/* <Footer /> */}
                </div>
            </BlockUi>
        </div>
    )
}

Layout.propTypes = {
    blocking: PropTypes.bool,
    showMenuBar: PropTypes.bool,
    title: PropTypes.string,
    showMenu: PropTypes.bool
}

Layout.defaultProps = {
    blocking: false,
    showMenuBar: true,
    showMenu: true
}

export default Layout

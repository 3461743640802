import styled from 'styled-components'
import media from '../../styles/media'
import { Footer } from '../../assets'

export const FooterWrapper = styled.footer`
    padding: 0 0 40px;
    text-align: center;
    ${media.sm`padding: 35px 0;`}
    background-color: ${Footer.backgroundColor};
`

export const Copyright = styled.div`
    height: 155px;
    font-size: 12px;
    text-align: left;
    ${media.md`
      font-size: 14px;
    `};
    @media screen and (max-width: 768px) {
        height: auto;
    }

    .copyright-text {
        background-color: #ced4da;
        width: 100%;
        vertical-align: middle;
        height: 1px;
        ${media.sm`
          width: 100%;
          text-align: left;
          display: inline-block;
        `}
    }

    .terms-conditions {
        color: #ced4da;
        display: inline-block;
        width: 100%;
        vertical-align: middle;
        ${media.sm` 
          width: 100%; 
          text-align: left;
        `}
        @media screen and (max-width: 768px) {
            text-align: center;
            margin-top: 1.5rem;
            padding: 0 30px;
        }
    }

    p {
        margin-top: 0;
        margin-bottom: 0.3rem;
    }
`

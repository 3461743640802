import React, { Component } from 'react'
import { Redirect } from 'react-router'
import { getUnixTime } from 'date-fns'

import AuthContext from '../../context/AuthContext'

function withAccessGranted(WrappedComponent, privilege, exclusiveRole = true) {
    return class extends Component {
        constructor(props) {
            super(props)
            this.state = {
                accessLevelRequired: privilege,
                exclusive: exclusiveRole
            }
        }

        render() {
            const { exclusive, accessLevelRequired } = this.state
            return (
                <AuthContext.Consumer>
                    {({ user, token }) => {
                        const isvalidToken = user.expires > getUnixTime(new Date())
                        if (!token || !isvalidToken) {
                            localStorage.clear()
                            sessionStorage.clear()
                            return <Redirect push to="/login" />
                        }
                        const role = accessLevelRequired.find((item) => item === user.role.id)
                        const isGranted = !!(exclusive && role)
                        const props = {
                            user,
                            token
                        }

                        return (
                            <div>
                                {isGranted ? (
                                    <WrappedComponent {...this.props} {...props} />
                                ) : (
                                    <Redirect push to="/404" />
                                )}
                            </div>
                        )
                    }}
                </AuthContext.Consumer>
            )
        }
    }
}

export default withAccessGranted

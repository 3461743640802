export const handleStatusParam = (statusP, invoice) => {
    if (statusP && invoice && invoice?.transaction?.transaction_status !== statusP) {
        // The transaction status has a different value than queryString removeIt if BREAKS
        return invoice.transaction.transaction_status
    }

    if (statusP) {
        return statusP
    } else if (!statusP && invoice) {
        return invoice.transaction.transaction_status
    }
    return statusP
}

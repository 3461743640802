import React, { useState, useRef } from 'react'
import { Section, Title, Content, AccordionAction, Icon } from './style'

function AccordionFooter({ title, itemName, itemContent }) {
    const [setActive, setActiveState] = useState('')
    const [setHeight, setHeightState] = useState('0px')

    const content = useRef(null)

    function toggleAccordion() {
        setActiveState(setActive === '' ? 'active' : '')
        setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`)
    }

    return (
        <Section active={setActive}>
            <div className="copyright-text"></div>
            <AccordionAction onClick={toggleAccordion}>
                <Title active={setActive}>{title}</Title>
                <Icon active={setActive} className="icon-abajo" />
            </AccordionAction>
            <Content ref={content} style={{ maxHeight: `${setHeight}` }}>
                {itemContent}
            </Content>
            {itemName === 'faqs' ? (<div className="copyright-text"></div>) : (<></>)}
        </Section>
    )
}

export default AccordionFooter

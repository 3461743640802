import React from 'react'
import { TitleWrapper, CloseButton } from './style'

const Title = ({ title }) => {
    return (
        <TitleWrapper>
            <div className="w-100 d-flex justify-content-end px-3">
                <CloseButton to={'/'}>
                    <span>&times;</span>
                </CloseButton>
            </div>
            {title}
        </TitleWrapper>
    )
}

export default Title

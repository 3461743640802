import React from 'react'
import PropTypes from 'prop-types'
import { FooterWrapper, Copyright } from '../style'
import Contact from './Contact'
import HelpPage from './Help'
import Logo from '../../Logo'

const DesktokFooter = ({ text }) => {
    const currentYear = new Date().getFullYear()

    return (
        <FooterWrapper>
            <div className="container">
                <Copyright>
                    <div className="copyright-text"></div>
                    <div className="row">
                        <div className="col-4" style={{ height: 'fit-content' }}>
                            <Logo logoType={'header'} />
                        </div>
                        <div className="col-4">
                            <Contact showTitle={true} />
                        </div>
                        <div className="col-4">
                            <HelpPage showTitle={true} />
                        </div>
                    </div>
                    <div className="copyright-text"></div>
                    <div className="terms-conditions">
                        Copyright {currentYear}. Todos los derechos reservados {text.toUpperCase()}{' '}
                        ©
                    </div>
                </Copyright>
            </div>
        </FooterWrapper>
    )
}

export default DesktokFooter

DesktokFooter.propTypes = {
    text: PropTypes.string
}

DesktokFooter.defaultProps = {
    text: 'PPM ® Ecuador.'
}

import React from 'react'
import Input from '../../../../components/Form/Input'
import Select from '../../../../components/Form/Select'
import { CustomSelect } from '../../../Commerce/Form/style'
import { handleOnKeyDownNumber, validateIdentification } from './helpers'

const ContentSecondInput = ({
    optionsIdentificationType,
    control,
    errors,
    clearErrors,
    setValue,
    getValues,
    optionsEstablishmentType,
    selectedEstablishmentType,
    optionsEstablishments,
    token,
    setError
}) => {
    const maxLengthTypeIdentification = () => {
        let number = 0
        getValues('identificationTypeID') && getValues('identificationTypeID')['label'] === 'CI'
            ? (number = 10)
            : getValues('identificationTypeID') &&
              getValues('identificationTypeID')['label'] === 'RUC'
            ? (number = 13)
            : (number = 16)

        return number
    }
    return (
        <>
            {optionsIdentificationType.length > 0 && (
                <Select
                    styles={CustomSelect}
                    label="Tipo de identificación*"
                    name="identificationTypeID"
                    control={control}
                    options={optionsIdentificationType}
                    error={errors.identificationTypeID && errors.identificationTypeID?.message}
                    onFocus={() => {
                        clearErrors('identification')
                        setValue('identification', '')
                    }}
                />
            )}
            <Input
                label="Nº de identificación*"
                type="text"
                name="identification"
                placeholder="Ingresar número de Identificación"
                control={control}
                error={errors.identification && errors.identification?.message}
                disabled={!getValues('identificationTypeID')}
                onBlur={() => validateIdentification(getValues, clearErrors, token, setError)}
                onFocus={() => {
                    clearErrors('identification')
                }}
                maxLength={maxLengthTypeIdentification()}
                onKeyDown={
                    getValues('identificationTypeID') &&
                    getValues('identificationTypeID')['label'] === 'CI'
                        ? handleOnKeyDownNumber
                        : getValues('identificationTypeID') &&
                          getValues('identificationTypeID')['label'] === 'RUC'
                        ? handleOnKeyDownNumber
                        : () => {}
                }
            />
            <Select
                styles={CustomSelect}
                label="Tipo de establecimiento*"
                name="establishmentType"
                control={control}
                options={optionsEstablishmentType}
                error={errors.establishmentType && errors.establishmentType?.message}
            />

            {Number(selectedEstablishmentType) === 1 && (
                <Select
                    styles={CustomSelect}
                    label="Establecimiento*"
                    name="establishmentID"
                    control={control}
                    options={optionsEstablishments}
                    error={errors.establishmentID && errors.establishmentID?.message}
                />
            )}
        </>
    )
}

export default ContentSecondInput

export const handleLoadSeller = async (
    response,
    setShowLoader,
    setRequestData,
    setInitialtData
) => {
    const {
        commerce_id,
        name,
        last_name,
        identification_type,
        identification,
        email,
        code,
        username,
        establishment
    } = response.data.data

    let commerceId = 0
    if (commerce_id === 0 && establishment) {
        commerceId = establishment.commerce_id
    } else {
        commerceId = commerce_id
    }

    const loadData = {
        name,
        lastname: last_name,
        identificationTypeID: {
            label: identification_type.name,
            value: identification_type.id
        },
        identification,
        id: response.data.data.id,
        email,
        code,
        username,
        commerceId
    }

    if (establishment) {
        loadData.establishmentID = {
            value: establishment.id,
            label: establishment.name
        }
    }

    setShowLoader(false)
    setRequestData(loadData)
    setInitialtData(loadData)
}

export const handleError = (error, setErrorRequest, displayToast) => {
    if (error && error.response) {
        setErrorRequest(error.response.data.errors)
        let description = error.response.data.errors ?? error.response.data.errors
        let dataDescription = ''
        if(description != null || description != undefined){
            Object.keys(description).map(function (key) {
                return dataDescription = dataDescription + description[key] + '. '
            })
        }
        displayToast({
            type: 'custom-fail',
            title: error.response.data.message,
            message: dataDescription
        })
    } else {
        displayToast({
            type: 'fail',
            message: error.response?.data?.message
        })
    }
}

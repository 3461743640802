import styled from 'styled-components'
import { Body, PayPage } from '../../assets'
import CustomButton from '../../components/CustomButton'

export const TotalContainer = styled.div`
    background-color: ${PayPage.tabWrapperBackground};
    justify-content: center;
    margin: 0 0;
    padding: 0 0;
    min-height: 944px;
    .container-responsive {
        height: 100%;
        width: 75%;
        display: block;
        margin: 0 auto;
    }
    @media screen and (max-width: 992px, min-height: 844px) {
        .container-responsive {
            padding: 0;
        }
        .container-responsive {
            height: 100%;
            width: 100%;
            display: block;
            margin: 0 auto;
        }
    }
`
export const StatusContainer = styled.div`
    background-color: ${PayPage.tabWrapperBackground};
    border-radius: 50px;
    width: 100%;
    height: auto;
    margin-top: 25px;
    @media screen and (max-width: 992px) {
        width: 100%;
        margin-top: 25px;
    }
`
export const StatusImage = styled.div`
    justify-content: center;
    margin-top: 50px;
    @media screen and (max-width: 992px) {
        font-size: 0.75rem;
    }
    img.image-responsive {
        width: 60px;
        height: 60px;
        @media screen and (max-width: 992px) {
            width: 40px;
            height: 40px;
        }
    }
`
export const StatusWrapper = styled.div`
    padding: 2rem 0;
    @media screen and (max-width: 992px) {
        margin-bottom: 1rem;
    }
`
export const StatusTitle = styled.div`
    font-size: 35px;
    font-weight: 700;
    color: ${Body.label.color};
    text-align: center;
    margin-bottom: 2rem;

    @media screen and (max-width: 992px) {
        font-size: 20px;
    }
`
export const StatusText = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: ${Body.label.color};
    text-align: center;

    @media screen and (max-width: 992px) {
        font-size: 0.875rem;
    }
`
export const StatusButton = styled(CustomButton)`
    width: 214px;
    max-width: 100%;
    heigth: 52px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 12px;
    border-radius: 27.79px;
    @media screen and (max-width: 992px) {
        font-size: 0.75rem;
    }
`
export const Wrapper = styled.div`
    padding: 2rem 0;
    @media screen and (max-width: 992px) {
        margin-bottom: 1rem;
        padding: 2rem;
        border-radius: 5px;
        border: solid 1px #e3ecf2;
        background-color: #fff;
    }
`
export const StyledTitle = styled.h3`
    font-size: 1rem;
    font-weight: 500;
    color: ${Body.label.color};
    text-align: center;
    margin-bottom: 2rem;
    @media screen and (max-width: 992px) {
        font-size: 0.875rem;
    }
`

export const ItemRow = styled.div`
    border-bottom: solid 1px ${PayPage.borderColor};
    padding: 0.8rem 0;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 992px) {
        flex-direction: column;
    }
    :last-child {
        border-bottom: none;
    }
`

export const Label = styled.div`
    color: ${Body.label.color};
    font-size: 1rem;
    display: flex;
    width: 100%;
    @media screen and (max-width: 992px) {
        justify-content: center;
        font-size: 0.75rem;
        text-align: center;
    }
`

export const Value = styled.div`
    color: ${PayPage.value};
    font-size: 1rem;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    @media screen and (max-width: 992px) {
        background-color: transparent;
        justify-content: center;
        font-size: 0.75rem;
    }
`

export const StyledPending = styled.h3`
    color: ${PayPage.value};
    font-size: 1rem;
    font-weight: 300;
    padding: 2rem;
    text-align: center;
    span {
        color: ${PayPage.title};
        font-weight: bold;
    }
    @media screen and (max-width: 992px) {
        font-size: 0.875rem;
    }
`

export const Container = styled.div`
    .adaptative {
        flex-flow: wrap;
        padding: 0;
        font-size: 12px;
    }
`
export const PrimaryCard = styled.div`
    background-color: ${PayPage.tabWrapperBackground};
    box-shadow: 0 0 16px 12px ${PayPage.borderColor};
    border: solid 0.5px ${PayPage.borderColor};
    border-radius: 10px;
    margin: 1rem 0;
    padding: 1rem;
    .container-responsive {
        width: 75%;
        display: block;
        margin: 0 auto;
    }
    @media screen and (max-width: 992px) {
        .container-responsive {
            padding: 0;
        }
        .container-responsive {
            width: 100%;
            display: block;
            margin: 0 auto;
        }
    }
`

import React, { useContext, useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router'
import Layout from '../../../layouts'

import userAccess from '../../../userAccess'

import 'react-tabs/style/react-tabs.css'

import withAccessGranted from '../../../components/WithAccessGranted'
import CustomButton from '../../../components/CustomButton'
import Chip from '../../../components/Chip'
import Title from '../../../components/CustomTitle'

import { StyledActionCard } from './style'

import { steps } from './components/steps'
import SellerService from '../../../services/Seller'
import AuthContext from '../../../context/AuthContext'
import GeneralContext from '../../../context/GeneralContext'
import { ProjectName } from '../../../assets'
import { handleError, handleLoadSeller } from './helpers'
import Content from './components/Content'

const SellersEditForm = () => {
    const { id } = useParams()
    const [initialData, setInitialtData] = useState()

    const [actionForm, setActionForm] = useState()
    const [currentStep, setCurrentStep] = useState(0)
    const [requestData, setRequestData] = useState()
    const [showLoader, setShowLoader] = useState(true)
    const { user, token } = useContext(AuthContext)
    const { displayToast } = useContext(GeneralContext)
    const [errorRequest, setErrorRequest] = useState()

    const history = useHistory()

    //--- isMobile ---
    const [width, setWidth] = useState(window.innerWidth)
    const [isMobile, setIsMobile] = useState(false)

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth)
    }
    useEffect(() => {
        setIsMobile(width <= 768)
    }, [width])
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])
    //--- end isMobile ---

    const sendData = async () => {
        setShowLoader(true)
        setErrorRequest(undefined)
        const data = {
            id: requestData.id,
            identification_type_id: requestData?.identificationTypeID?.value,
            name: requestData?.name,
            last_name: requestData?.lastname,
            identification: requestData?.identification,
            email: requestData?.email,
            code: requestData?.code
        }

        if (requestData?.establishmentType?.value === 1) {
            data.establishment_id = requestData?.establishmentID.value
        } else if (requestData?.establishmentType?.value === 2) {
            data.commerce_id = requestData?.commerceId
        }

        try {
            const response = await SellerService.Update(token, data)
            if (response) {
                setShowLoader(false)
                displayToast({
                    type: 'ok',
                    message: 'Vendedor actualizado correctamente'
                })
                history.push('/sellers/list')
            }
        } catch (error) {
            setShowLoader(false)
            handleError(error, setErrorRequest, displayToast)
        }
    }

    const loadSeller = async () => {
        try {
            const response = await SellerService.getById(token, id)
            if (response) {
                handleLoadSeller(response, setShowLoader, setRequestData, setInitialtData)
            }
        } catch (error) {
            displayToast({
                type: 'fail',
                message: error.response?.data?.message
            })
            history.push('/sellers/list')
        }
    }

    useEffect(() => {
        if (id) {
            loadSeller()
        } else {
            displayToast({
                type: 'fail',
                message: 'Se requiere código de vendedor'
            })
            history.push('/sellers/list')
        }
    }, [])

    return (
        <Layout blocking={showLoader} activeItem="sellers">
            <Title>{ProjectName}</Title>
            <div className="row">
                <div className="col-12">
                    <Chip title="RUC" info={user.identification} />
                </div>
            </div>
            <StyledActionCard active title="Información del vendedor" icon="vendedores">
                <CustomButton
                    onClick={() => history.push('/sellers/list')}
                    buttonType="tertiary"
                    category="danger"
                    icon="cancelar"
                    borderWidth="1px"
                    padding={isMobile ? '0.25em 0' : '0.25em 0.5em'}
                >
                    <span className="hide-movil">Cancelar</span>
                </CustomButton>
            </StyledActionCard>
            <Content
                requestData={requestData}
                initialData={initialData}
                steps={steps}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setActionForm={setActionForm}
                setRequestData={setRequestData}
                setShowLoader={setShowLoader}
                sendData={sendData}
                errorRequest={errorRequest}
                actionForm={actionForm}
                isMobile={isMobile}
            />
        </Layout>
    )
}
export default withAccessGranted(SellersEditForm, [
    userAccess.COMMERCE.ACCESS_LEVEL,
    userAccess.ESTABLISHMENT.ACCESS_LEVEL
])

import styled from 'styled-components'
import CustomButton from '../../components/CustomButton'
import { PayPage, Form, FontSizeLabel, Body, Input, Button } from '../../assets'

export const Wrapper = styled.div`
    height: auto;
`
export const Title = styled.h3`
    font-size: 1rem;
    font-weight: bold;
    color: ${PayPage.title};
    text-align: center;
    margin-bottom: 2rem;
`

export const ItemRow = styled.div`
    padding: 0.8rem 0;
    @media screen and (max-width: 400px) {
        width: 90%;
        margin: 0 auto;
        display: block;
    }
`

export const Label = styled.div`
    color: ${PayPage.titleValue};
    font-size: 1rem;
    @media screen and (max-width: 992px) {
        font-size: 0.75rem;
        text-align: center;
    }
`

export const Value = styled.div`
    color: ${PayPage.value};
    font-size: 1rem;
    text-align: right;
    @media screen and (max-width: 992px) {
        font-size: 0.75rem;
        text-align: center;
    }
`

export const Box = styled.div`
    border-radius: 5px;
    background-color: ${PayPage.tabWrapperBackground};
    padding: 2rem;
`

export const TitleBox = styled.h5`
    font-size: 1rem;
    color: ${PayPage.value};
    text-align: center;
    @media screen and (max-width: 992px) {
        font-size: 0.875rem;
    }
`

export const StyledButton = styled(CustomButton)`
    width: 20rem;
    max-width: 100%;
    margin: auto;
    font-weight: bold;
    @media screen and (max-width: 992px) {
        font-size: 0.75rem;
    }
`

export const PtoPLogo = styled.img`
    max-width: 100%;
    width: 12rem;
    margin-top: 1rem;
`

export const StyledTYC = styled.a`
    margin: 0 0.25rem;
    color: ${Button.primary.defaultColor.active.color};
`

export const ButtonsContainer = styled.div`
    align-item: center;
`

export const H6 = styled.h6`
    font-weight: 600;
    font-size: 12px;
`

export const StyledText = styled.div`
    color: #242331;
    font-size: 16px;
    font-weight: 400;
    text-align: initial;
    @media screen and (max-width: 992px) {
        font-size: 12px;
    }
`

export const StyledError = styled.span`
    color: ${Form.error.color};
    background: ${Form.error.background};
    font-size: ${FontSizeLabel};
`

export const InnerFormContainer = styled.div`
    width: 30rem;
    max-width: 100%;
    margin: auto;
    padding: 5px 1px 0 1px;
    div input::placeholder {
        color: ${Input.placeholder};
    }
`

export const MainContainer = styled.div`
    width: auto;
    height: auto;
    background-color: ${PayPage.tabWrapperBackground};
    border-width: 0px;
    border-radius: 35px;
    border-style: solid;
    margin-top: 25px;
`

export const EstablishmentName = styled.h1`
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    text-align: center;
    color: #242331;
    margin-top: 2rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 992px) {
        font-size: 24px;
    }
`

export const DescriptionText = styled.h5`
    color: ${Body.label.color};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

    @media screen and (max-width: 992px) {
        font-size: 0.75rem;
    }
`

export const PtopLogoContainer = styled.div`
    top: 10px;
    text-align: center;
    margin-bottom: 28px;
`

export const Politics = styled.div`
    width: 100%;
    color: ${Body.label.color};
    padding: 5px 15px 0 15px;
    font-size: 14px;
    font-weight: 400;
    text-align: justify;
    margin-top: 15px;
`

export const CheckContainer = styled.div`
    padding: 8px 15px 0 15px;
    label {
        cursor: pointer;
        color: ${Body.label.color};
        font-size: 14px;
        font-weight: 400;
    }
    input {
        /*height: 24px;*/
        min-width: 13px;
        width: auto;
        margin: 0 10px 0 17px;
        accent-color: ${Button.primary.defaultColor.active.color};
    }
`
export const StyledCheckbox = styled.div`
    display: block;
    margin-bottom: 15px;

    label {
        cursor: pointer;
        color: ${Body.label.color};
        font-size: 16px;
        font-weight: 400;
        position: relative;
        display: flex;
    }
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }
    label:before {
        content: '';
        -webkit-appearance: none;
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid ${Button.primary.defaultColor.active.color};
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        width: 21px;
        height: 21px;
        margin-right: 5px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 8px;
        width: 7px;
        height: 13px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    input:checked + label:before {
        border-radius: 4px;
        border: 1px solid ${Button.primary.defaultColor.active.color};
        background-color: ${Button.primary.defaultColor.active.color};
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
`

import styled from 'styled-components'
import { Accordion } from '../../assets'

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 0;
    padding: 0px 30px 0px 30px;
    }
`

export const Content = styled.div`
    overflow: hidden;
    transition: max-height 0.6s ease;
`

export const Text = styled.div`
    margin-top: 10px;
    color: ${Accordion.color};
    font-size: ${Accordion.fontSize};
`

export const Title = styled.div`
    font-weight: bold;
    font-size: ${Accordion.fontSize};
    color: ${Accordion.color};
    padding-top: 3px;
    padding-bottom: 3px;
`

export const AccordionAction = styled.div`
    cursor: pointer;
    align-items: center;
    border: none;
    outline: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const Icon = styled.i`
    ${(props) => (props.active ? 'transform: rotate(180deg)' : '')};
`

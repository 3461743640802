import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import * as jwtDecode from 'jwt-decode'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import AuthContext from './context/AuthContext'
import GeneralContext from './context/GeneralContext'
import Routes from './routes'
import Notification from './components/Notification'

class App extends React.Component {
    childRef = React.createRef(null)

    constructor(props) {
        super(props)
        this.state = {
            checkedLogin: false,
            token: '',
            user: {},
            notification: {
                type: '',
                message: '',
                title: ''
            }
        }
    }

    componentDidMount() {
        try {
            const token = window.localStorage.getItem('token')
            if (token) {
                const user = JSON.parse(window.localStorage.getItem('user'))
                const decodeToken = jwtDecode(token)
                if (decodeToken.exp !== undefined) {
                    user.expires = decodeToken.exp
                }

                this.setState({ token, user, checkedLogin: true })
            } else {
                this.setState({ token: '', user: {}, checkedLogin: true })
            }
        } catch {
            this.setState({ token: '', user: {}, checkedLogin: true })
        }
    }

    setLoggedUser = async () => {
        const token = window.localStorage.getItem('token')

        if (token) {
            const user = JSON.parse(window.localStorage.getItem('user'))
            const decodeToken = jwtDecode(token)
            if (decodeToken.exp !== undefined) {
                user.expires = decodeToken.exp
            }
            this.setState({ token, user, checkedLogin: true })
        }
    }

    onSetNotification = (notification) => {
        this.setState({ notification })
    }

    displayToast = (notification) => {
        if (this.childRef) {
            this.childRef.current.notify(
                notification.type,
                notification.message,
                notification.title
            )
        }
        return true
    }

    render() {
        const { checkedLogin, token, user, notification } = this.state
        if (!checkedLogin) return null
        return (
            <GeneralContext.Provider
                value={{
                    displayToast: this.displayToast,
                    onSetNotification: this.onSetNotification
                }}
            >
                <AuthContext.Provider
                    value={{
                        token,
                        user,
                        updateToken: this.setLoggedUser
                    }}
                >
                    <Notification ref={this.childRef}>
                        {({ notify }) => {
                            if (notification.type !== '') {
                                notify(notification.type, notification.message, notification.title)
                            }
                            return <></>
                        }}
                    </Notification>
                    <Routes />
                </AuthContext.Provider>
            </GeneralContext.Provider>
        )
    }
}

ReactDOM.render(<App />, document.getElementById('root'))
unregisterServiceWorker()

import React, { useState, useContext } from 'react'
import {
    CustomLink,
    CustomProfile,
    HeaderWrapper,
    ProfileWrapper,
    ProfileBody,
    NavbarToggler,
    Navbar,
    NavbarHeader,
    NavbarContent,
    WrapperLinks,
    WrapperItems
} from './style'
import { Link } from 'react-router-dom'
import UserService from '../../services/User'
import AuthContext from '../../context/AuthContext'
import GeneralContext from '../../context/GeneralContext'
import CustomLogo from '../Logo/index'
import userAccess from '../../userAccess'
import { Sidebar } from '../../assets'

const Header = ({ activeItem, showMenu }) => {
    const [menu, setMenu] = useState(false)
    const [showNavbar, setShowNavbar] = useState(false)

    const handleMenu = () => {
        setMenu(!menu)
    }
    const { displayToast } = useContext(GeneralContext)
    const { user, token } = useContext(AuthContext)
    const { name, shortname, email, identification, username, role } = user

    const LogoutFallBack = async () => {
        try {
            const response = await UserService.logout(token)
            if (response) {
                localStorage.clear()
                sessionStorage.clear()
                window.location.href = '/login'
            }
        } catch (error) {
            displayToast({
                type: 'fail',
                message: 'Ocurrió un error al desloguear el usuario'
            })
            localStorage.clear()
            sessionStorage.clear()
            window.location.href = '/login'
        }
    }

    function returnTo() {
        let url = ''
        if (user && token){
            switch (role.id) {
                case userAccess.ADMIN.ACCESS_LEVEL:
                    url = '/commerce/list'
                    break
                case userAccess.COMMERCE.ACCESS_LEVEL:
                    url = '/establishments/list'
                    break
                case userAccess.ESTABLISHMENT.ACCESS_LEVEL:
                    url = '/sellers/list'
                    break
                case userAccess.SELLER.ACCESS_LEVEL:
                    url = '/invoices/list'
                    break
                default:
                    url = '/'
            }
        }else{
            url = '/'
        }
        return url;
    }

    return (
        <HeaderWrapper className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="navbar-brand">
                <CustomLink to={returnTo()}>
                    <CustomLogo logoType="header" />
                </CustomLink>
            </div>
            <div className="collapse navbar-collapse" />
            <div className="form-inline my-2 my-lg-0">
                {token && (
                    <ProfileWrapper className="btn-group">
                        <CustomProfile onClick={handleMenu}>{shortname}</CustomProfile>
                        <ProfileBody className={`dropdown-menu ${menu ? 'show' : ''}`}>
                            <div className="container">
                                <div onClick={handleMenu} className="close">
                                    <span>&times;</span>
                                </div>
                                <div className="profile-logo">{shortname}</div>
                                <div className="profile-body">
                                    <h1 className="profile-title">{name || identification}</h1>
                                    <h2 className="profile-mail">{email}</h2>
                                </div>
                            </div>
                            <div className="dropdown-divider" />

                            <div className="container">
                                <div className="profile-body">
                                    <h3>
                                        Usuario: <span className="profile-mail">{username}</span>
                                    </h3>
                                    <h3>
                                        Perfil:{' '}
                                        <span className="profile-mail">
                                            {role && role.id == '1'
                                                ? 'Administrador'
                                                : role.id == '2'
                                                ? 'Comercio'
                                                : role.id == '3'
                                                ? 'Establecimiento'
                                                : role.id == '4'
                                                ? 'Vendedor'
                                                : ''}
                                        </span>
                                    </h3>
                                </div>
                            </div>
                            <div className="dropdown-divider" />
                            <div className="dropdown-item">
                                <CustomLink to="/faqs">
                                    <div>Preguntas frecuentes</div>
                                </CustomLink>
                            </div>
                            {user.role && user.role.id !== userAccess.ADMIN.ACCESS_LEVEL && (
                                <div className="dropdown-item">
                                    <CustomLink to="/password/change" replace>
                                        Cambio de contraseña
                                    </CustomLink>
                                </div>
                            )}
                            <div className="dropdown-divider" />
                            <div onClick={() => LogoutFallBack()} className="dropdown-item">
                                <div className="dropdown-close">Cerrar sesión</div>
                            </div>
                        </ProfileBody>
                    </ProfileWrapper>
                )}
            </div>

            {/* Navbar MOBILE */}
            {showMenu && token && (
                <>
                    <NavbarToggler
                        className="navbar-toggler custom-toggler"
                        type="button"
                        onClick={() => setShowNavbar(!showNavbar)}
                    >
                        <span className="navbar-toggler-icon"></span>
                    </NavbarToggler>

                    <Navbar isOpen={showNavbar}>
                        <NavbarHeader>
                            <CustomProfile onClick={handleMenu}>{shortname}</CustomProfile>
                            <div className="wrapper-main">
                                <div className="wrapper-info">
                                    <div className="info">
                                        <span className="info-name">{name || identification}</span>
                                        <span className="info-email">{email}</span>
                                    </div>
                                    <div
                                        className="btn-close"
                                        onClick={() => setShowNavbar(!showNavbar)}
                                    >
                                        <span>&times;</span>
                                    </div>
                                </div>
                                <div className="config">
                                    {user.role && user.role.id !== userAccess.ADMIN.ACCESS_LEVEL && (
                                        <>
                                            <Link
                                                className="config-link"
                                                to="/password/change"
                                                replace
                                            >
                                                Cambio de contraseña
                                            </Link>{' '}
                                        </>
                                    )}
                                </div>
                            </div>
                        </NavbarHeader>
                        <NavbarContent>
                            <WrapperLinks>
                                {token && user?.role?.id === userAccess.ADMIN.ACCESS_LEVEL ? (
                                    <CustomLink
                                        className={`item ${activeItem === 'commerces' && 'active'}`}
                                        to="/commerces"
                                    >
                                        {Sidebar.hasIcon && <i className="icon-comercio" />}
                                        <div className="item-text">Comercios</div>
                                    </CustomLink>
                                ) : (
                                    <></>
                                )}

                                {(token && user?.role?.id === userAccess.COMMERCE.ACCESS_LEVEL) ||
                                user?.role?.id === userAccess.ESTABLISHMENT.ACCESS_LEVEL ? (
                                    <CustomLink
                                        className={`item ${
                                            activeItem === 'establishments' && 'active'
                                        }`}
                                        to="/establishments"
                                    >
                                        {Sidebar.hasIcon && <i className="icon-establecimiento" />}
                                        <div className="item-text">Establecimientos</div>
                                    </CustomLink>
                                ) : (
                                    <></>
                                )}

                                {(token && user?.role?.id === userAccess.COMMERCE.ACCESS_LEVEL) ||
                                user?.role?.id === userAccess.ESTABLISHMENT.ACCESS_LEVEL ? (
                                    <CustomLink
                                        className={`item ${activeItem === 'sellers' && 'active'}`}
                                        to="/sellers"
                                    >
                                        {Sidebar.hasIcon && <i className="icon-vendedores" />}
                                        <div className="item-text">Vendedores</div>
                                    </CustomLink>
                                ) : (
                                    <></>
                                )}

                                {token && user?.role?.id === userAccess.COMMERCE.ACCESS_LEVEL ? (
                                    <CustomLink
                                        className={`item ${
                                            activeItem === 'administrators' && 'active'
                                        }`}
                                        to="/administrators"
                                    >
                                        {Sidebar.hasIcon && <i className="icon-admin" />}
                                        <div className="item-text">Administradores</div>
                                    </CustomLink>
                                ) : (
                                    <></>
                                )}

                                {token && user?.role?.id === userAccess.SELLER.ACCESS_LEVEL ? (
                                    <CustomLink
                                        className={`item ${activeItem === 'invoices' && 'active'}`}
                                        to="/invoices/list"
                                    >
                                        {Sidebar.hasIcon && <i className="icon-pagos" />}
                                        <div className="item-text">Gestion de pagos</div>
                                    </CustomLink>
                                ) : (
                                    <></>
                                )}
                            </WrapperLinks>
                            <WrapperItems>
                                <Link className="item" to="/faqs">
                                    Preguntas frecuentes
                                </Link>
                                <div
                                    className="item item-sign-out"
                                    onClick={() => LogoutFallBack()}
                                >
                                    Cerrar sesión
                                </div>
                            </WrapperItems>
                            <div className="container-logo">
                                <CustomLogo logoType="header" />
                            </div>
                        </NavbarContent>
                    </Navbar>
                </>
            )}
        </HeaderWrapper>
    )
}
export default Header

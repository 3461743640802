import styled from 'styled-components'
import CustomButton from '../../components/CustomButton'
import { Login, PayPage, BackgroundEvenList, Body, Button } from '../../assets'

export const Container = styled.div`
    justify-content: center;
    margin: 0 0;
    padding: 0 0;
    min-height: 944px;
    .container-responsive {
        height: 100%;
        width: 75%;
        display: block;
        margin: 0 auto;
    }
    @media screen and (max-width: 992px, min-height: 844px) {
        .container-responsive {
            padding: 0;
        }
        .container-responsive {
            height: 100%;
            width: 100%;
            display: block;
            margin: 0 auto;
        }
    }
`
export const PaymentContainer = styled.div`
    padding: 0 0;
    background-color: ${PayPage.tabWrapperBackground};
    border-radius: 50px;
    width: 100%;
    height: auto;
    margin-top: 10px;
    @media screen and (min-width: 767px) {
        box-shadow: none;
        margin-top: 40px;
    }
`
export const Wrapper = styled.div`
    padding: 2rem 0;
`
export const Title = styled.h3`
    font-size: 1rem;
    font-weight: bold;
    color: ${PayPage.title};
    text-align: center;
    margin-bottom: 2rem;
`

export const TitleBendo = styled.div`
    font-size: 28px;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
    color: #242331;
    margin-top: 2rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 992px) {
        font-size: 24px;
    }
`
export const DetailBendo = styled.div`
    margin-bottom: 15px;
`
export const ItemRowBendo = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 1rem;
    width: 100%;
    text-align: center;

    @media screen and (min-width: 168px) {
        flex-direction: row;
        padding: 0 0;
    }
`
export const LabelBendo = styled.div`
    color: ${Body.label.color};
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: right;

    @media screen and (max-width: 992px) {
        font-size: 0.75rem;
        text-align: center;
    }
`
export const ValueBendo = styled.div`
    color: ${Body.label.color};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    width: 300px;
    justify-content: left;

    @media screen and (max-width: 992px) {
        font-size: 0.75rem;
        text-align: center;
    }
`
export const LabelTotal = styled.div`
    color: #242331;
    font-size: 16px;
    font-weight: 700;
    text-align: initial;
    padding-left: 100px;
    @media screen and (max-width: 992px) {
        font-size: 12px;
    }
`
export const ValueTotal = styled.div`
    color: #242331;
    font-size: 30px;
    font-weight: 700;
    @media screen and (max-width: 992px) {
        font-size: 16px;
        text-align: center;
    }
`

export const ItemRow = styled.div`
    align-items: center;
    border-bottom: solid 1px ${PayPage.borderColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 1rem auto 0;
    padding: 0 0 1rem;
    width: 65%;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        padding: 0.8rem 0;
        width: 100%;
    }
`

export const ItemRowPolitics = styled.div`
    border: none;
    margin-top: 2.5rem;
    width: 100%;
    div {
        width: 100%;
    }
`

export const Label = styled.div`
    color: ${Body.label.color};
    font-size: 16px;
    font-weight: 400;
    text-align: initial;
    @media screen and (max-width: 992px) {
        font-size: 12px;
    }
`

export const Value = styled.div`
    color: ${Body.label.color};
    font-size: 16px;
    font-weight: 400;
    @media screen and (max-width: 992px) {
        font-size: 12px;
        text-align: center;
    }
`

export const Politics = styled.div`
    width: 100%;
    color: ${Body.label.color};
    padding: 1rem;
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
`

export const PoliticsLabel = styled.div`
    color: ${Body.label.color};
    font-size: 16px;
    font-weight: 400;
    text-align: initial;
    padding-left: 43px;
    text-align: justify;
`

export const CheckContainer = styled.div`
    display: flex;
    justify-content: start;
    margin: 0 auto;
    padding: 0 25px;
`
export const Section = styled.div`
    border-radius: 17.1px 17.1px 17.1px 17.1px;
    margin: 0 25px 0 25px;
`
export const BoxSection = styled.div`
    padding: 5px 10px 5px 10px;
    &:nth-child(even) {
        background-color: ${BackgroundEvenList};
    }
    &:nth-child(odd) {
        background-color: white;
    }
`
export const TitleBox = styled.h5`
    font-size: 1rem;
    color: ${PayPage.value};
    text-align: center;
    @media screen and (max-width: 992px) {
        font-size: 0.875rem;
    }
`
export const Amount = styled.p`
    font-size: 14px;
    color: ${PayPage.title};
    text-align: center;
    @media screen and (max-width: 992px) {
        font-size: 12px;
    }
`
export const PaySure = styled.p`
    font-size: 9px;
    font-weight: 300;
    color: ${Body.label.color};
    text-align: center;
    margin-top: 30px;
    letter-spacing: 0.01rem;
    @media screen and (max-width: 992px) {
        font-size: 10px;
    }
`
export const AmountResponsive = styled.p`
    display: block;
    font-size: 0.875rem;
    color: ${PayPage.title};
    text-align: center;
`

export const StyledButton = styled(CustomButton)`
    width: 12rem;
    max-width: 100%;
    margin: auto;
    font-weight: bold;
    border-radius: 27.79px;
    @media screen and (max-width: 992px) {
        font-size: 0.75rem;
    }
`

export const PtoPLogo = styled.img`
    max-width: 100%;
    width: 10rem;
    margin-top: -15px;
    margin-bottom: 10px;
`

export const StyledTYC = styled.a`
    margin: 0 0.25rem;
    color: ${Button.primary.defaultColor.active.color};
`

export const EstablishmentLogo = styled.img`
    width: 100px;
    height: auto;
    max-height: 100px;
    border: solid gray 1px;
    @media screen and (max-width: 992px) {
        margin: 0 auto;
        display: block;
    }
`
export const PrimaryCard = styled.div`
    background-color: #fff;
    box-shadow: 0 0 16px 12px ${PayPage.borderColor};
    border: solid 0.5px ${PayPage.borderColor};
    border-radius: 10px;
    margin: 1rem 0;
    padding: 1rem;
    .container-responsive {
        width: 75%;
        display: block;
        margin: 0 auto;
    }
    @media screen and (max-width: 992px) {
        .container-responsive {
            padding: 0;
        }
        .container-responsive {
            width: 100%;
            display: block;
            margin: 0 auto;
        }
    }
`
export const LinkContainer = styled.div`
    margin-top: 30px;
    font-size: 13px;
    a {
        color: ${Login.link.color};
    }
    @media screen and (max-width: 992px) {
        margin-top: 0;
    }
`
export const DescWrapper = styled.div`
    color: ${PayPage.title};
    font-size: 14px;
    text-align: center;
    margin-bottom: 1rem;
`
export const StyledCheckbox = styled.div`
    display: block;
    margin-bottom: 15px;

    label {
        cursor: pointer;
        color: ${Body.label.color};
        font-size: 16px;
        font-weight: 400;
        position: relative;
        display: flex;
    }
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer;
    }
    label:before {
        content: '';
        -webkit-appearance: none;
        background-color: #ffffff;
        border-radius: 4px;
        border: 1px solid ${Button.primary.defaultColor.active.color};
        padding: 10px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        cursor: pointer;
        width: 21px;
        height: 21px;
        margin-right: 5px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    input:checked + label:after {
        content: '';
        display: block;
        position: absolute;
        top: 3px;
        left: 8px;
        width: 7px;
        height: 13px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    input:checked + label:before {
        border-radius: 4px;
        border: 1px solid ${Button.primary.defaultColor.active.color};
        background-color: ${Button.primary.defaultColor.active.color};
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
`

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ButtonComponent from './style'
import { Button } from '../../assets'

const CustomButton = ({
    children,
    onClick,
    type,
    size,
    icon,
    hasIcon,
    iconPosition,
    buttonType,
    block,
    category,
    iconColor,
    isMenu,
    ...props
}) => {
    const [style, setStyle] = useState({})

    const handleColorStyle = (colorStyle, type) => {
        if (type === 1) {
            let aux = {
                hoverBackground: colorStyle?.hover.backgroundColor,
                hoverColor: colorStyle.hover.color,
                hoverBorder: colorStyle.hover.borderColor,
                activeBackground: colorStyle.active.backgroundColor,
                activeColor: colorStyle.active.color,
                activeBorder: colorStyle.active.borderColor,
                disableBackground: colorStyle.disable.backgroundColor,
                disableColor: colorStyle.disable.color,
                disableBorder: colorStyle.disable.borderColor
            }
            setStyle({
                ...colorStyle,
                ...aux
            })
        } else {
            setStyle({
                ...colorStyle
            })
        }
    }
    const handleCategoryStyle = (category, btn) => {
        const actions = {
            active: (_) => {
                const { activeColor } = Button[btn]
                handleColorStyle(activeColor, 1)
            },
            success: (_) => {
                const { successColor } = Button[btn]
                handleColorStyle(successColor, 1)
            },
            danger: (_) => {
                const { dangerColor } = Button[btn]
                handleColorStyle(dangerColor, 1)
            },
            auxiliar: (_) => {
                const { auxiliarColor } = Button[btn]
                handleColorStyle(auxiliarColor, 4)
            }
        }
        // eslint-disable-next-line no-prototype-builtins
        if (actions.hasOwnProperty(category)) {
            actions[category]()
        } else {
            const { defaultColor } = Button[btn]
            handleColorStyle(defaultColor, 1)
        }
    }
    useEffect(() => {
        const actions = {
            // eslint-disable-next-line no-unused-vars
            secondary: (_) => handleCategoryStyle(category, 'secondary'),
            // eslint-disable-next-line no-unused-vars
            tertiary: (_) => handleCategoryStyle(category, 'tertiary'),
            // eslint-disable-next-line no-unused-vars
            link: (_) => handleCategoryStyle(category, 'link')
        }
        // eslint-disable-next-line no-prototype-builtins
        if (actions.hasOwnProperty(buttonType)) {
            actions[buttonType]()
        } else {
            handleCategoryStyle(category, 'primary')
        }
    }, [children, icon, block, category, buttonType])

    return (
        <ButtonComponent
            {...style}
            disabled={block}
            className={`btn btn-${size}`}
            onClick={onClick}
            type={type}
            hasIcon={!!icon}
            isMenu={isMenu}
            {...props}
        >
            {hasIcon && icon && iconPosition === 'left' && (
                <i className={`icon-${icon} ${iconColor}`} />
            )}
            {children}
            {hasIcon && icon && iconPosition === 'right' && (
                <i className={`icon-${icon} ${iconColor}`} />
            )}
        </ButtonComponent>
    )
}
CustomButton.propTypes = {
    size: PropTypes.oneOf(['xs', 'sm', 'lg']),
    type: PropTypes.oneOf(['button', 'submit', 'reset']),
    buttonType: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'link']),
    icon: PropTypes.oneOf([
        '',
        'abajo',
        'admin',
        'advertencia',
        'arrear',
        'arriba',
        'borrar',
        'buscar',
        'calendario',
        'cancelar',
        'carga',
        'check',
        'cliente',
        'conciliadores',
        'derecha',
        'descargar',
        'agregar',
        'direccion',
        'editar',
        'enviar',
        'establecimiento',
        'excel',
        'genererar_c',
        'imagen',
        'info',
        'izquierda',
        'mas_info',
        'opcion',
        'pagos',
        'politicas',
        'prefactura',
        'vendedores',
        'excel2',
        'error',
        'comercio'
    ]),
    category: PropTypes.oneOf(['default', 'active', 'success', 'danger', 'auxiliar']),
    block: PropTypes.bool,
    hasIcon: PropTypes.bool,
    onClick: PropTypes.func,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    iconColor: PropTypes.string,
    isMenu: PropTypes.bool
}

CustomButton.defaultProps = {
    size: 'lg',
    type: 'button',
    category: 'default',
    icon: '',
    buttonType: 'primary',
    block: false,
    hasIcon: Button.hasIcon,
    iconPosition: 'left',
    iconColor: '',
    isMenu: false,
    onClick: () => {}
}

export default CustomButton

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import media from '../../styles/media'
import { Header, Sidebar } from '../../assets'

export const HeaderWrapper = styled.header`
    background-color: ${Header.backgroundColor} !important;
    box-shadow: 0 0 16px 12px ${Header.shadow};
    border: solid 0.5px ${Header.border};
    padding: 0.5rem 2rem;
    @media screen and (max-width: 992px) {
        padding: 0.5rem 1rem;
        box-shadow: none;
        margin: 0 0 1.5rem;
        .form-inline {
            display: none;
        }
        border: none;
    }
`

export const ProfileWrapper = styled.div`
    border-left: solid 1px ${Header.profile.borderLeft};
    padding: 0 50px;
`

export const CustomProfile = styled.div`
    border-radius: 50%;
    padding: 12px;
    background-color: ${Header.profile.color};
    cursor: pointer;
    color: ${Header.profile.backgroundColor};
    width: 45px;
    height: 45px;
    text-align: center;
`

export const ProfileBody = styled.div`
    border-radius: ${Header.profile.borderRadius};
    width: 300px;
    margin-top: 20px;
    margin-left: -200px;
    background-color: ${Header.profile.body.backgroundColor};
    padding: 20px auto;
    .container {
        margin: auto;
        display: flex;

        .close {
            position: absolute;
            top: 15px;
            right: 5px;
            font-size: 25px;
            line-height: 0;
            cursor: pointer;
        }

        .profile-logo {
            text-align: center;
            background-color: ${Header.profile.body.profile.backgroundColor};
            color: ${Header.profile.body.profile.color};
            border-radius: 50%;
            letter-spacing: -0.86px;
            padding: 14px;
            font-size: 24px;
            width: 64px;
            height: 64px;
        }
        .profile-body {
            width: 180px;
            margin-top: 15px;
            .profile-title {
                font-size: 15px;
                color: ${Header.profile.body.profile.title.color};
            }
            .profile-mail {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 11.5px;
                color: ${Header.profile.body.profile.mail.color};
            }
            h3 {
                font-size: 13px;
                color: ${Header.profile.body.profile.title.color};
            }
        }
    }
    .dropdown-item {
        padding: 5px 12px;
        font-size: 12px;
        cursor: pointer;
        a {
            color: ${Header.profile.body.profile.link.color} !important;
            text-decoration: none;
        }
        .dropdown-close {
            color: ${Header.profile.body.profile.close.color} !important;
        }
    }

    .dropdown-divider {
        margin: 0.5rem 15px;
        border-top: 1px solid ${Header.profile.body.profile.divider.color};
    }
`

export const CustomLink = styled(Link)`
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
    ${media.sm`
  padding: 5px 0;
  `}
    ${media.md`
  padding: 9px 0;
  `}
`
export const NavbarToggler = styled.button`
    border: none;
    display: block;
    font-size: 1.5rem;
    padding: 0.25rem 0 0.25rem 0.75rem;
    &.custom-toggler .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(32,138,255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    @media screen and (min-width: 992px) {
        display: none;
        padding: 0.25rem 0.75rem;
    }
`

export const Navbar = styled.div`
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    background-color: #f2f8ff;
    overflow-x: hidden;
    transition: 0.5s;
    width: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    display: grid;
    grid-template-rows: auto 1fr;

    @media screen and (min-width: 576px) {
        width: ${({ isOpen }) => (isOpen ? '350px' : '0')};
    }
`

export const NavbarHeader = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
    grid-gap: 12px;
    padding: 12px;
    .wrapper-info {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 1fr auto;
        grid-gap: 8px;
        .info {
            display: grid;
            align-content: space-between;
            .info-name {
                font-size: 1.1rem;
                color: ${Header.profile.body.profile.title.color};
            }
            .info-email {
                font-size: 0.8rem;
                color: ${Header.profile.body.profile.mail.color};
            }
        }
        .btn-close {
            display: flex;
            align-items: center;
            color: ${Header.profile.body.profile.mail.color};
            font-size: 2.5rem;
            line-height: 20px;
            margin-top: -20px;
            &:hover {
                color: #8caab2;
                cursor: pointer;
            }
        }
    }
    .config {
        color: #6ad5ef;
        a {
            color: #6ad5ef;
            text-decoration: underline;
        }
    }
`

export const NavbarContent = styled.div`
    display: grid;
    grid-template-rows: 1fr auto 0.5fr;
    .container-logo {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

export const WrapperLinks = styled.div`
    .item {
        height: auto;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: auto 1fr;
        grid-gap: 18px;
        padding: 12px 32px;
        color: #bad0d7;
        border-left: 4px solid transparent;
        box-shadow: 1px 1px 2px 0px #bad0d7;
        i {
            font-size: 1.8rem;
        }
        .item-text {
            display: flex;
            align-items: center;
        }
        &.active {
            color: #1f8aff;
            border-left: 4px solid;
        }
        &.item-download {
            background-color: #57d1f0;
            color: #fff;
            text-decoration: underline;
        }
    }
`

export const WrapperItems = styled.div`
    background-color: #fff;
    a,
    .item {
        display: block;
        padding: 18px 32px;
        color: #bad0d7;
        box-shadow: 1px 1px 2px 0px #bad0d7;
        &.item-sign-out {
            color: #1f8aff;
        }
    }
`

export const Separator = styled.div`
    border-bottom: solid 1px ${Sidebar.separator};
`

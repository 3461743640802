import styled from 'styled-components'
import { Tab, Form } from '../../../assets'

export const TabWrapper = styled.div`
    margin: auto;
    box-shadow: 0 0 16px 12px rgba(59, 83, 99, 0.06);
    background-color: ${Tab.backgroundColor};
`

export const TabBody = styled.div`
    margin: auto;
    max-width: 350px;
    background-color: ${Tab.backgroundColor};
`

export const TabFooter = styled.div`
    padding: 30px;
    background-color: ${Tab.backgroundFooterColor};
    width: 100%;
    margin: 5px auto 0;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        padding: 0;
        background: none;
    }
`
export const CircleWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 0 8px;

    div:last-child {
        margin-right: 0;
    }
    @media screen and (min-width: 768px) {
        margin: 0 auto 8px;
    }
`

export const CircleActive = styled.div`
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin: 0 22px 0 0;
    border: solid 0.7px ${Form.circle.inactiveColor};
    background-color: ${Form.circle.activeColor};
`

export const CircleInactive = styled.div`
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin: 0 22px 0 0;
    border: solid 0.5px ${Form.circle.inactiveColor};
    background-color: ${Form.circle.inactiveColor};
`
export const CustomSelect = {
    dropdownIndicator: (styles) => ({
        ...styles,
        color: `${Form.circle.activeColor} !important`
    }),
    indicatorSeparator: (styles) => ({
        ...styles,
        width: '0'
    })
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'
import {
    Wrapper,
    StyledError,
    StyledLabel,
    StyledInput,
    StyledText,
    TooltipIcon,
    EyePasswordWrapper
} from '../style'
import Tooltip from '../Tooltip'
import eyeSvg from '../../../assets/images/eye.svg'

const Input = ({
    label,
    error,
    placeholder,
    type,
    name,
    control,
    showTooltip,
    message,
    icon,
    showPassword,
    ...props
}) => {
    const [hidePassword, setHidePassword] = useState(true)
    const {
        field: { ref, ...inputProps }
    } = useController({
        name,
        control
    })

    return (
        <Wrapper hasError={`${error}`}>
            <StyledLabel>
                <StyledText>
                    {showTooltip && (
                        <Tooltip message={message}>
                            <TooltipIcon className={`icon-${icon}`} />
                        </Tooltip>
                    )}
                    {label}
                </StyledText>

                <StyledInput
                    type={hidePassword ? type : 'text'}
                    placeholder={placeholder}
                    inputRef={ref}
                    {...inputProps}
                    {...props}
                />
                {type === 'password' && showPassword && (
                    <EyePasswordWrapper onClick={() => setHidePassword(!hidePassword)}>
                        <img src={eyeSvg} width={20} />
                    </EyePasswordWrapper>
                )}
            </StyledLabel>
            {error && <StyledError>{error}</StyledError>}
        </Wrapper>
    )
}

Input.propTypes = {
    label: PropTypes.string,
    type: PropTypes.oneOf(['text', 'number', 'password', 'email']),
    placeholder: PropTypes.string,
    error: PropTypes.string
}

Input.defaultProps = {
    label: '',
    placeholder: '',
    type: 'text',
    error: ''
}

export default Input

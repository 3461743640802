import { createContext } from 'react'

const newUser = () => ({
    active: true,
    identification: '',
    username: '',
    name: '',
    email: '',
    role: {},
    shortname: 0
})

const AuthContext = createContext({
    token: '',
    user: newUser()
})

export default AuthContext

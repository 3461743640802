import styled from 'styled-components'
import { Tab } from '../../../assets'

import { ActionCard } from '../../../components/CustomCard'

export const StyledActionCard = styled(ActionCard)``

export const TabWrapper = styled.div`
    margin: auto;
    box-shadow: 0 0 16px 12px rgba(59, 83, 99, 0.06);
    background-color: ${Tab.backgroundColor};
`

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DesktopFooter from './custom/DesktopFooter'
import MobileFooter from './custom/MobileFooter'

const NewFooter = ({ text }) => {
    //--- isMobile ---
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(false);

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        setIsMobile(width <= 768)
    }, [width]);

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, []);
    //--- end isMobile ---
    return ( !isMobile ? (<DesktopFooter text={text} />) : (<MobileFooter text={text} />));
}

export default NewFooter

NewFooter.propTypes = {
    text: PropTypes.string
}

NewFooter.defaultProps = {
    text: 'PPM ® Ecuador.'
}

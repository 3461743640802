import API from '../Api'

class EstablishmentService {
    static List(auth, limit, offset, order) {
        return API({
            method: 'GET',
            url: `/establishments?limit=${limit}&offset=${offset}&trashed=true&${order}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static getByID(auth, id) {
        return API({
            method: 'GET',
            url: `/establishments/${id}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static Filter(auth, value) {
        return API({
            method: 'GET',
            url: `/establishments/find/${value}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static Save(auth, data) {
        return API({
            method: 'POST',
            url: '/establishments',
            headers: auth && { Authorization: `Bearer ${auth}` },
            data
        })
    }

    static Update(auth, data) {
        return API({
            method: 'PUT',
            url: `/establishments/${data.id}`,
            headers: auth && { Authorization: `Bearer ${auth}` },
            data
        })
    }

    static Delete(auth, id) {
        return API({
            method: 'DELETE',
            url: `/establishments/${id}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static Activate(auth, id) {
        return API({
            method: 'PUT',
            url: `/establishments/activate/${id}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static ValidateDuplicatedCode(auth, code) {
        return API({
            method: 'GET',
            url: `/establishment/duplicatedCode?code=${code}`,
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static uploadFile(auth, data) {
        return API({
            method: 'POST',
            url: '/establishments/upload-file',
            headers: auth && { Authorization: `Bearer ${auth}` },
            data
        })
    }

    static ListToSelect(auth) {
        return API({
            method: 'GET',
            url: 'establishments/list',
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static updatePolitic(auth, id, data) {
        return API({
            method: 'PUT',
            url: `/establishments/update-tyc/${id}`,
            headers: auth && { Authorization: `Bearer ${auth}` },
            data
        })
    }
}

export default EstablishmentService

import styled from 'styled-components'
import { Link } from 'react-router-dom'
import media from '../../styles/media'
import { Sidebar } from '../../assets'

export const CustomLink = styled(Link)`
    display: inline;
    margin: 0 auto;
    height: 100%;
    width: auto;
    border-bottom: ${Sidebar.border};
    color: ${Sidebar.item.color};
    text-decoration: none !important;
    a {
        :active {
            text-decoration: none;
        }
    }
    ${media.sm`
    padding: 5px 0;
  `}
    ${media.md`
    padding: 9px 0;
  `}
  .item-container {
        align-items: center;
        display: flex;
        margin: 11px 0;
        padding: 12px 9px;
        white-space: nowrap;
        text-decoration: none !important;
        i {
            color: ${Sidebar.item.icon.color};
            font-size: 18px;
        }
        :hover {
            border-radius: ${Sidebar.borderRadius};
            color: ${Sidebar.item.hover.color};
            background: ${Sidebar.item.hover.backgroundColor};
            i {
                color: ${Sidebar.item.hover.color};
            }
        }
        :active {
            color: ${Sidebar.item.clicked.color};
            background: ${Sidebar.item.clicked.backgroundColor};
            text-decoration: none;
        }
        @media screen and (min-width: 1200px) {
            padding: 12px;
        }
    }
    .active {
        border-radius: ${Sidebar.borderRadius};
        color: ${Sidebar.item.active.color};
        background: ${Sidebar.item.active.backgroundColor};
        i {
            color: ${Sidebar.item.active.color};
        }
        box-shadow: 0 2px 12px 16px rgba(0, 0, 0, 0.06);
    }
    .item-text {
        padding-left: 7px;
        font-size: 11px;
        @media screen and (min-width: 1200px) {
            font-size: 12px;
        }
    }
`

export const SideWrapper = styled.div`
    background-color: ${Sidebar.backgroundColor};
    min-height: 90vh;
    padding: 63px 0 264px 43px;
    box-shadow: 0 0 16px 12px rgba(59, 83, 99, 0.05);
    border: solid 0.5px ${Sidebar.border};
    padding: 43px 0;
    background-color: ${Sidebar.backgroundColor};
    @media screen and (max-width: 992px) {
        display: none;
    }
`

export const MenuContainer = styled.div`
    padding: 0 5%;
    @media screen and (min-width: 1200px) {
        padding: 0 10%;
    }
`

export const Item = styled.nav`
    background-color: ${Sidebar.item.backgroundColor};
`

export const Separator = styled.div`
    border-bottom: solid 1px ${Sidebar.separator};
`

export const SeparatorRight = styled.div`
    height: 2px;
    margin: 0 0 30px 138px;
    background-color: ${Sidebar.separator};
`

export const SeparatorLeft = styled.div`
    height: 2px;
    margin: 30px 138px 0 0;
    background-color: ${Sidebar.separator};
`

export const InfoWrapper = styled.div`
    margin-top: 80px;
`

export const InfoContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 10%;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${Sidebar.textColor};
`

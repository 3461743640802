import API from '../Api'
import { encryptText } from '../../helpers/encriptation'

class UserService {
    static getSalesAmount(auth) {
        return API({
            method: 'GET',
            url: '/user',
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static getByToken(userToken) {
        return API({
            method: 'GET',
            url: `/users/password/${userToken}`
        })
    }

    static getInvoicePublicToken(id, token) {
        return API({
            method: 'GET',
            url: `/P2P?id=${id}&t=${token}`
        })
    }

    static editPassword(data) {
        const requestEncrypt = encryptText(JSON.stringify(data));
        return API({
            method: 'PUT',
            url: '/users/change-password',
            data: { data: requestEncrypt }
        })
    }

    static logout(auth) {
        return API({
            method: 'POST',
            url: '/auth/logout',
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static getByAuth(auth) {
        return API({
            method: 'GET',
            url: '/user',
            headers: auth && { Authorization: `Bearer ${auth}` }
        })
    }

    static editPasswordByAuth(auth, data) {
        const requestEncrypt = encryptText(JSON.stringify(data));
        return API({
            method: 'PUT',
            url: '/users/password-edit',
            headers: auth && { Authorization: `Bearer ${auth}` },
            data: { data: requestEncrypt }
        })
    }
}

export default UserService
